import Input from "../components/input/Input";
import * as Yup from "yup";
import Button from "../components/button/Button";
import { Formik, Form } from "formik";
import Steps from "../components/steps/Steps";
import Paragraph from "../components/paragraph/Paragraph";
import { ReactComponent as PasswordIlustration } from "../assets/images/password-ilustration.svg";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuthPersistStore from "../store/authPersistStore";
import { useChangePassword } from "../hooks/useChangePassword";
import { ROUTES } from "../constants/routes";

const ChangePassword = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 800px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });
  const { user, setLogin } = useAuthPersistStore();
  const { mutate, isLoading } = useChangePassword();
  const navigate = useNavigate();
  const location = useLocation();
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Obavezno polje"),
    password: Yup.string()
      .required("Obavezno polje")
      .min(8, "Mora imati najmanje 8 karaktera")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
        "Najmanje 8 karaktera (Obavezno veliko i malo slovo, broj)"
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        "Potvrda lozinke mora se poklapati sa lozinkom"
      )
      .required("Obavezno polje"),
  });

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location?.search);

      const userId = searchParams.get("userId");
      const token = searchParams.get("token");

      if (userId && token) {
        setUserId(userId);
        setToken(token);
      }
    }
  }, [location]);

  return (
    <div className="create-profile">
      <Steps steps={4} activeStep={3} />
      <div className="create-profile_page">
        <div>
          <div className="create-profile_header">
            <div className="create-profile_header-content">
              <h1>Kreiraj novu lozinku</h1>
              <Paragraph variant="p2">{user?.email}</Paragraph>
            </div>
            {isTablet && !isDesktop && <PasswordIlustration />}
          </div>
          <Formik
            onSubmit={(values, actions) => {
              const { oldPassword, password } = values;

              mutate(
                {
                  token: token,
                  userId: userId,
                  oldPassword,
                  newPassword: password,
                },
                {
                  onSuccess: (data) => {
                    // @ts-ignore
                    const { accessToken, user } = data;
                    setLogin(accessToken, user);
                    navigate(ROUTES.resetPasswordSuccess);
                  },
                  onError: (response) => {
                    actions.setFieldError(
                      "oldPassword",
                      // @ts-ignore
                      response?.response?.data?.message ||
                        "Uneti podaci nisu ispravni"
                    );
                  },
                }
              );
            }}
            validationSchema={validationSchema}
            initialValues={{
              oldPassword: "",
              password: "",
              confirmPassword: "",
            }}
            enableReinitialize
          >
            <Form className="create-profile_form">
              <Input
                name="oldPassword"
                label="Unesite trenutnu lozinku"
                type="password"
                placeholder="Unesite trenutnu lozinku"
                autoFocus
              />
              <Input
                name="password"
                label="Unesite novu lozinku"
                type="password"
                placeholder="Unesite novu lozinku"
                autoFocus
              />
              <Input
                name="confirmPassword"
                label="Ponovite novu lozinku"
                type="password"
                placeholder="Ponovite novu lozinku"
                autoFocus
              />
              <Button
                variant="primary"
                label="Kreiraj lozinku"
                type="submit"
                hasLoader={true}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Form>
          </Formik>
        </div>
        {isDesktop && <PasswordIlustration />}
      </div>
    </div>
  );
};

export default ChangePassword;

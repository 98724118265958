import { useQuery } from "@tanstack/react-query";
import { SubscriptionDetails } from "../types";

import { GET } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useSubscriptionDetails = ({isAuthenticated}: {
  isAuthenticated: boolean
}) => {
  const query = useQuery<SubscriptionDetails, Error, SubscriptionDetails>({
    // @ts-ignore
    queryKey: ["SUBSCRIPTION-DETAILS"],
    queryFn: async () => {
      const data = await GET(ENDPOINTS.subscriptionDetails);
      return data;
    },
    staleTime: 5 * (60 * 1000), 
    cacheTime: 7 * (60 * 1000),
    enabled: !!isAuthenticated,
  });

  return { ...query };
};

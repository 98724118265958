import React from "react";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import Paragraph from "../paragraph/Paragraph";
import { useNavigate } from "react-router-dom";
import UserInitials from "./UserInitials";
import useAuthPersistStore from "../../store/authPersistStore";
import { ROUTES } from "../../constants/routes";
import { useSubscriptionDetails } from "../../hooks/useSubscriptionDetails";

interface UserProps {
  hideSettings?: boolean;
  close?: () => void;
}

const User: React.FC<UserProps> = ({ hideSettings, close }) => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuthPersistStore();
  const { data: subscriptionDetails } = useSubscriptionDetails({
    isAuthenticated,
  });

  if (!close) {
    return (
      <div className="user">
        <UserInitials />
        <div className="user_information">
          <Paragraph variant="p1" className="u-playfair-display">
            {user?.userProfile?.fullName}
          </Paragraph>
          {subscriptionDetails?.subscriptionType && (
            <Paragraph variant="p2">
              {subscriptionDetails?.subscriptionType === "Monthly"
                ? "Mesečni"
                : "Godišnji"}{" "}
              plan
            </Paragraph>
          )}
        </div>
        {!hideSettings && (
          <div className="user_settings">
            <SettingsIcon />
          </div>
        )}
      </div>
    );
  }

  return (
    <button
      className="user"
      onClick={() => {
        if (!close) {
          return;
        }

        navigate(ROUTES.profile);
        close && close();
      }}
    >
      <UserInitials />
      <div className="user_information">
        <Paragraph variant="p1" className="u-playfair-display">
          {/* @ts-ignore */}
          {user?.userProfile?.fullName}
        </Paragraph>
        {subscriptionDetails?.subscriptionType && (
          <Paragraph variant="p2">
            {subscriptionDetails?.subscriptionType === "Monthly"
              ? "Mesečni"
              : "Godišnji"}{" "}
            plan
          </Paragraph>
        )}
      </div>
      {!hideSettings && (
        <div className="user_settings">
          <SettingsIcon />
        </div>
      )}
    </button>
  );
};

export default User;

import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ResetPasswordIlustration } from "../assets/images/success-ilustration.svg";
import Button from "../components/button/Button";
import Paragraph from "../components/paragraph/Paragraph";
import { useEffect, useState } from "react";
import Spinner from "../components/spinner/Spinner";
import { queryClient } from "../App";

const CheckoutSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState("/home");
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState("");

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location?.search);

      const redirectTo = searchParams.get("redirectTo");
      const type = searchParams.get("type");

      if (redirectTo) {
        setRedirectUrl(redirectTo);
      } else {
        setRedirectUrl("/home");
      }

      if (type) {
        setType(type);
      } else {
        setType("");
      }
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 10000);
  }, []);

  const getSpinnerLabel = () => {
    if (type === "payment_method_update" || type === "subscription_cancel") {
      return "Obrađujemo promenu, molimo sačekajte";
    }

    return "Obrađujemo plaćanje, molimo sačekajte";
  };

  const getPageHeader = () => {
    if (type === "payment_method_update" || type === "subscription_cancel") {
      return "Izmena uspešno završena";
    }

    return "Kupovina uspešno završena";
  };

  const getPageBody = () => {
    if (type === "payment_method_update" || type === "subscription_cancel") {
      return "Sačuvajte email koji budete dobili kao potvrdu izmene.";
    }

    return "Sačuvajte email koji budete dobili kao potvrdu kupovine.";
  };

  if (isLoading) {
    return (
      <>
        <Spinner label={getSpinnerLabel()} />
      </>
    );
  }

  return (
    <div className="success">
      <div className="success_page">
        <ResetPasswordIlustration />
        <div className="success_content">
          <h1>{getPageHeader()}</h1>
          <div className="success_text">
            <Paragraph variant="p2">{getPageBody()}</Paragraph>
          </div>

          <div className="success_link">
            <Button
              variant="primary"
              label="Nastavi dalje"
              onClick={() => {
                navigate(redirectUrl);
                queryClient.invalidateQueries(["SUBSCRIPTION-DETAILS"]);
                queryClient.invalidateQueries(["MY-CONTENT-SERIAL-IDS"]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;

import { useNavigate } from "react-router-dom";
import { ReactComponent as EmailIlustration } from "../assets/images/email-ilustration.svg";
import Button from "../components/button/Button";
import Paragraph from "../components/paragraph/Paragraph";
import Steps from "../components/steps/Steps";
import useAuthPersistStore from "../store/authPersistStore";
import { useRequestEmailChange } from "../hooks/useRequestEmailChange";
import { ROUTES } from "../constants/routes";

const ChangeEmail = () => {
  const navigate = useNavigate();
  const { user } = useAuthPersistStore();
  const { mutate, isLoading } = useRequestEmailChange();

  return (
    <div className="create-profile-success">
      <Steps activeStep={1} steps={4} />
      <div className="create-profile-success_page">
        <EmailIlustration />
        <div className="create-profile-success_content">
          <div className="create-profile-success_header">
            <h1>Želite da promenite email adresu?</h1>
            <Paragraph variant="p2">{user.email}</Paragraph>
          </div>
          <div className="create-profile_cta">
            <Button
              maxContent={true}
              onClick={() => navigate(ROUTES.profile)}
              label="Nazad"
              variant="secondary"
            />
            <Button
              variant="primary"
              label="Dalje"
              type="submit"
              hasLoader={true}
              isLoading={isLoading}
              onClick={() =>
                mutate(
                  {},
                  {
                    onSuccess: () => {
                      navigate(ROUTES.changeEmailSuccess);
                    },
                  }
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;

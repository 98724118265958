export const ROUTES = {
    register: '/register',
    registerSuccess: '/register/success',
    createProfile: '/create-profile',
    createProfileSuccess: '/create-profile/success',
    home: '/home',
    subscription: '/subscription',
    login: '/login',
    changePassword: "/change-password",
    changePasswordRequest: '/change-password/request',
    changePasswordRequestSuccess: '/change-password/request/success',
    changePasswordSuccess: '/change-password/success',
    resetPassword: '/reset-password',
    resetPasswordSuccess: '/reset-password/success',
    changeEmail: '/change-email',
    changeEmailSuccess: '/change-email/success',
    resetEmail: '/reset-email',
    resetEmailSuccess: '/reset-email/success',
    changeProfile: '/change-profile',
    changeProfileSuccess: '/change-profile/success',
    forgotPassword: '/forgot-password',
    forgotPasswordSuccess: '/forgot-password/success',
    profile: "/profile",
    educators: "/educators",
    educator: "/educator",
    aboutUs: "/about-us",
    content: "/content",
    myContent: "/my-content",
    serial: "/serial",
    pageNotFound: "/*",
    checkoutSuccess: "/checkout-success",
    legal: "/legal",
    confirmPlan: '/confirm-plan',
}
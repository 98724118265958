import React from "react";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import Search from "../search/Search";
import { Form, Formik } from "formik";
import LoggedOutNavigation from "../navigation/LoggedOutNavigation";
import Button from "../button/Button";
import User from "../user/User";
import useAuthPersistStore from "../../store/authPersistStore";
import LoggedInNavigation from "../navigation/LoggedInNavigation";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { trim } from "../../utils/trim";

interface SidebarProps {
  isOpen: boolean;
  close: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, close }) => {
  const { isAuthenticated } = useAuthPersistStore();
  const navigate = useNavigate();

  return (
    <div
      className={trim(
        `sidebar ${isOpen ? "sidebar--open" : "sidebar--closed"}`
      )}
    >
      <header className="sidebar_header">
        <div
          className="sidebar_logo"
          onClick={() => {
            navigate(ROUTES.home);
            close();
          }}
        >
          <Logo />
        </div>
        <button className="sidebar_trigger" onClick={close}>
          <Cancel />
        </button>
      </header>
      <div className="sidebar_body">
        <Formik
          initialValues={{ search: "" }}
          onSubmit={(values) => {
            navigate(`${ROUTES.content}?search=${values?.search}`);
            close();
          }}
        >
          <Form className="sidebar_form">
            <Search name="search" placeholder="Pronađi željeni sadržaj" />
          </Form>
        </Formik>
        {isAuthenticated && (
          <div className="sidebar_user">
            <User close={close} />
          </div>
        )}
        <nav className="sidebar_nav">
          {isAuthenticated ? (
            <LoggedInNavigation close={close} />
          ) : (
            <LoggedOutNavigation close={close} />
          )}
        </nav>
        {!isAuthenticated && (
          <div className="sidebar_cta">
            <Button
              label="Registruj se"
              variant="primary"
              onClick={() => {
                navigate(ROUTES.register);
                close();
              }}
            />
            <Button
              label="Prijavi se"
              variant="secondary"
              onClick={() => {
                navigate(ROUTES.login);
                close();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;

import { useQuery } from "@tanstack/react-query";
import { ContentFormatType } from "../types";

import { GET } from "../services/requests";

export const useFeaturedContentFormats = () => {
  const query = useQuery<ContentFormatType[], Error, ContentFormatType[]>({
    // @ts-ignore
    queryKey: ["CONTENT-FORMATS-FEATURED"],
    queryFn: async () => {
      const data = await GET("/content-formats/featured");
      return data;
    },
    staleTime: 5 * (60 * 1000), 
    cacheTime: 7 * (60 * 1000),
  });

  return { ...query };
};

import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowShortDown } from "../../assets/icons/arrow-short-down.svg";
import { useField } from "formik";
import { ReactComponent as ConfirmIcon } from "../../assets/icons/confirm.svg";
import { trim } from "../../utils/trim";

interface FilterProps {
  label: string;
  name: string;
  items: {
    label: string;
    value: string;
  }[];
}

const Filter: React.FC<FilterProps> = ({ label, items, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [field, meta, helpers] = useField(name);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleOnChange = (value: string) => {
    const currentValue = field.value || [];
    const updatedValue = currentValue?.includes(value)
      ? currentValue.filter((val: string) => val !== value)
      : [...currentValue, value];

    helpers.setValue(updatedValue);
  };

  const filled = field?.value?.length;

  return (
    <div
      className={trim(
        `filter ${isOpen ? "filter--open" : ""} ${
          filled > 0 ? "filter--filled" : ""
        }`
      )}
      ref={ref}
    >
      <button
        className="filter_button"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="filter_label">{label}</span>
        {filled > 0 && <span className="filter_filters-active">{filled}</span>}
        <ArrowShortDown />
      </button>

      {isOpen && !!items && (
        <ul className="filter_dropdown">
          {items?.map(({ value, label }, index) => {
            return (
              <li key={index}>
                <div
                  className={trim(
                    `checkbox ${
                      !!field?.value?.includes(value) ? "checkbox--checked" : ""
                    } ${meta.error ? "checkbox--error" : ""}`
                  )}
                >
                  <label className="checkbox_inner">
                    <input
                      checked={field?.value?.includes(value)}
                      className="checkbox_field"
                      type="checkbox"
                      onChange={() => handleOnChange(value)}
                      value={value}
                    />

                    <div className="checkbox_indicator">
                      <ConfirmIcon />
                    </div>

                    {label && <p className="checkbox_label">{label}</p>}
                  </label>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Filter;

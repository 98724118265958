import Categories from "../sections/Categories";
import NewSerial from "../sections/NewSerial";
import Formats from "../components/formats/Formats";
import { useMediaQuery } from "react-responsive";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import SelectPlan from "../sections/SelectPlan";
import AuthedBanner from "../sections/AuthedBanner";
import NonAuthedBanner from "../sections/NonAuthedBanner";
import useAuthPersistStore from "../store/authPersistStore";
import { SerialType } from "../types";
import SerialSlides from "../sections/SerialSlides";
import { useFeaturedContentFormats } from "../hooks/useFeaturedContentFormats";
import { getImageRoute } from "../utils/getImageRoute";
import { useSortedContentFormats } from "../hooks/useSortedContentFormats";
import { useSubscriptionDetails } from "../hooks/useSubscriptionDetails";

const Home = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 480px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1040px)" });
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const size = useWindowSize();
  const { isAuthenticated } = useAuthPersistStore();
  const { data: subscriptionDetails } = useSubscriptionDetails({
    isAuthenticated,
  });

  const getSliderPaddings = () => {
    if (isDesktop) {
      return 0;
    }

    if (isTablet) {
      return 60;
    }

    return 30;
  };

  const perPage = () => {
    if (isDesktop) {
      return Math.floor(1 / 0.2395);
    }

    if (isTablet) {
      return Math.floor(1 / 0.325);
    }

    return Math.floor(1 / 0.42);
  };

  const getSlideWidth = (orientation: string) => {
    if (orientation === "vertical") {
      if (isDesktop) {
        return containerWidth * 0.2395;
      }

      if (isTablet) {
        return containerWidth * 0.325;
      }

      return containerWidth * 0.42;
    }

    if (orientation === "horizontal") {
      if (isDesktop) {
        return containerWidth * 0.493;
      }

      if (isTablet) {
        return containerWidth * 0.59;
      }

      return containerWidth * 0.75;
    }
  };

  const getSpaceBetweenSlides = () => {
    if (isDesktop) {
      return 18;
    }

    if (isTablet) {
      return 12;
    }

    return 6;
  };

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef?.current?.offsetWidth || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, size]);

  const { data: featuredContentFormats } = useFeaturedContentFormats();
  const { data: sortedContentFormats } = useSortedContentFormats();

  const SerialSlidesComponent = (contentFormat: any) => { // TODO: types
    return (<SerialSlides
      emptySlide={{
        type: "vertical",
        width: getSlideWidth("vertical"),
        aspectRatio: "3/4",
      }}
      // @ts-ignore
      contentFormat={contentFormat}
      component={(serial: SerialType) => (
        <img
          style={{ width: getSlideWidth("vertical"), aspectRatio: "3/4" }}
          src={getImageRoute(serial?.verticalCoverImage)}
          alt={`${serial?.title} - ${serial?.description}`}
        />
      )}
      options={{
        gap: getSpaceBetweenSlides(),
        padding: getSliderPaddings(),
        perMove: perPage(),
        perPage: perPage(),
      }}
    />)
  }

  return (
    <>
      <section className="home__background">
        {isAuthenticated ? (
          <AuthedBanner categories={featuredContentFormats} />
        ) : (
          <NonAuthedBanner />
        )}
      </section>
      <section>
        <NewSerial />
      </section>
      <section>
        <div className="home_grid" ref={containerRef}>
          {/**
           * ovo su 3 featured formata
           */}
          {sortedContentFormats && sortedContentFormats.slice(0, 3).map(SerialSlidesComponent)}
          {/**
           * ovo je onaj boks sa featured content formats i search
           */}
          {!isAuthenticated && <Formats categories={featuredContentFormats} />}
          {/**
           * ovo su featured tagovi
           */}
          <Categories />          
          {/**
           * ovo su ostali featured formati pored prva 3
           */}
          {sortedContentFormats && sortedContentFormats.slice(3).map(SerialSlidesComponent)}
        </div>
      </section>
      {!subscriptionDetails?.subscriptionType && (
        <section>
          <SelectPlan />
        </section>
      )}
    </>
  );
};

export default Home;

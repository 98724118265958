import { ROUTES } from "./routes";

export const kompanija = [
  {
    label: "O nama",
    link: ROUTES.aboutUs,
  },
  {
    label: "Edukatori",
    link: ROUTES.educators,
  },
  {
    label: "Uslovi korišćenja",
    link: ROUTES.legal,
  },
  {
    label: "Politika privatnosti",
    link: ROUTES.legal,
  },
];

export const istrazi = [
  {
    label: "Početna",
    link: ROUTES.home,
  },
  {
    label: "Odaberi plan",
    link: ROUTES.subscription,
  },
];

export const convertToIso8601 =(date: string) =>  {

    if(!date) {
        return;
    }
  // Split the date string into day, month, and year
  const [day, month, year] = date.split('.');

  // Create a new Date object (months are 0-indexed in JavaScript)
  const dateObject = new Date(`${year}-${month}-${day}`);

  // Convert the Date object to ISO 8601 format
  const isoDateString = dateObject.toISOString();

  return isoDateString;
}



export const convertFromISO8601 = (iso8601: string) => {

  if(!iso8601) {
    return null;
  }

  const dateObject = new Date(iso8601);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  const formattedDateString = `${day}.${month}.${year}`;

  return formattedDateString;
}

import React from "react";
import { EducatorType } from "../../types";
import Paragraph from "../paragraph/Paragraph";
import { getImageRoute } from "../../utils/getImageRoute";

const Educator = ({
  educator,
  onClick,
}: {
  educator: EducatorType;
  onClick: () => void;
}) => {
  const { fullName, profileImage, profession } = educator;

  return (
    <div className="educator" onClick={onClick}>
      <img
        src={getImageRoute(profileImage)}
        alt={`${fullName} - ${profession}`}
      />
      <div>
        <Paragraph variant="title">{fullName}</Paragraph>
        <Paragraph variant="p2">{profession}</Paragraph>
      </div>
    </div>
  );
};

export default Educator;

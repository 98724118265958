import { useNavigate } from "react-router-dom";
import { queryClient } from "../App";
import useAuthPersistStore from "../store/authPersistStore";
import { ROUTES } from "../constants/routes";

const useLogout = () => {
    const { setLogout } = useAuthPersistStore();
    const navigate = useNavigate();
   
    const handleLogout = () => {
        setLogout();
        queryClient.clear();
        navigate(ROUTES.login);
    }

    return {
        handleLogout
    }
}

export default useLogout;
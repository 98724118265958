import { useQuery } from "@tanstack/react-query";
import { TagType } from "../types";

import { GET } from "../services/requests";

export const useFeaturedTags = () => {
  const query = useQuery<TagType[]>({
    // @ts-ignore
    queryKey: ["FEATURED-TAGS"],
    queryFn: async () => {
      const data = await GET("/tags/featured");
      return data;
    },
    staleTime: 5 * (60 * 1000), 
    cacheTime: 7 * (60 * 1000),
  });

  return { ...query };
};

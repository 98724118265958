import isEmpty from "lodash.isempty";
import Empty from "../empty/Empty";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { ReactComponent as ArrowLongRight } from "../../assets/icons/arrow-long-right.svg";
import { ReactComponent as ArrowShortRight } from "../../assets/icons/arrow-short-right.svg";
import { ReactComponent as ArrowShortLeft } from "../../assets/icons/arrow-short-left.svg";

import Link from "../Link/Link";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { EducatorType } from "../../types";

interface SliderProps {
  options?: object;
  component: (item: any) => React.ReactNode;
  emptySlide: {};
  items: any[];
  educator: EducatorType;
}

const Slider = ({
  items,
  emptySlide,
  options,
  component,
  educator,
}: SliderProps) => {
  const ref = useRef();
  const navigate = useNavigate();
  const [splide, setSplide] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setSplide(ref.current.splide);
    }
  });

  return (
    <div className="slider">
      <div className="slider__top">
        <div className="slider__header">
          <h2>Najnoviji sadržaj</h2>

          <div className="slider__arrows">
            {/* @ts-ignore */}
            <button onClick={() => splide?.go("<")}>
              <ArrowShortLeft />
            </button>
            {/* @ts-ignore */}
            <button onClick={() => splide?.go(">")}>
              <ArrowShortRight />
            </button>
          </div>
        </div>
        <div className="slider__more">
          <Link
            variant="secondary"
            label="Više"
            icon={<ArrowLongRight />}
            onClick={() =>
              navigate(`${ROUTES.content}?educatorIds=${educator?.id}`)
            }
          />
        </div>
      </div>
      <div className="slider__container">
        {isEmpty(items) ? (
          // @ts-ignore
          <Empty emptySlide={emptySlide} />
        ) : (
          <div className="carousel">
            <Splide
              options={{
                type: "slide",
                autoWidth: true,
                autoHeight: true,
                arrows: false,
                ...options,
              }}
              // @ts-ignore
              ref={ref}
            >
              {items?.map((item, index) => (
                <SplideSlide key={index}>
                  <div className="slider__slide">{component(item)}</div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider;

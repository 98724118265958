import Button from "../button/Button";
import { ReactComponent as ShareIcon } from "../../assets/icons/share.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/confirm.svg";

import { useState } from "react";

const Share = () => {
  const [label, setLabel] = useState("Podeli");
  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = (text: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        setLabel("Link kopiran");
        setTimeout(() => {
          setLabel("Podeli");
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Button
      onClick={() => handleCopyClick(window.location.href)}
      label={label}
      variant="tertiary"
      leftIcon={label === "Podeli" ? <ShareIcon /> : <CheckIcon />}
    />
  );
};

Share.propTypes = {};

export default Share;

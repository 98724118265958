import ClipLoader from "react-spinners/ClipLoader";

const Spinner = ({ label }: { label?: string }) => {
  return (
    <div className="spinner__wrap">
      <ClipLoader
        color={"#dbc958"}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {label && (
        <h1 style={{ textAlign: "center", marginTop: "24px" }}>{label}</h1>
      )}
    </div>
  );
};

Spinner.propTypes = {};

export default Spinner;

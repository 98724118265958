import { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserInitials from "../user/UserInitials";
import { ROUTES } from "../../constants/routes";
import { trim } from "../../utils/trim";

interface HeaderProfileProps {
  items?: {
    link: string;
    label: string;
    icon?: ReactElement;
    onClick?: () => void;
  }[]; // Define the type of items as an array of strings
}

const HeaderProfile: React.FC<HeaderProfileProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null); // Specify the type of the ref
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={trim(`header_link ${isOpen ? "header_link--active" : ""}`)}
      ref={ref}
      onClick={() => (items ? setIsOpen(!isOpen) : navigate(ROUTES.profile))}
    >
      <UserInitials />

      {isOpen && items && (
        <ul className="header_dropdown">
          {items?.map(({ link, label, icon, onClick }, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  !!onClick ? onClick() : navigate(link);
                }}
              >
                <span>
                  {icon && icon}
                  <span>{label}</span>
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default HeaderProfile;

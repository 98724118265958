import Input from "../components/input/Input";
import * as Yup from "yup";
import Button from "../components/button/Button";
import { Formik, Form } from "formik";
import Steps from "../components/steps/Steps";
import Paragraph from "../components/paragraph/Paragraph";
import { ReactComponent as EmailIlustration } from "../assets/images/email-ilustration.svg";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useChangeEmail } from "../hooks/useChangeEmail";
import useAuthPersistStore from "../store/authPersistStore";
import { ROUTES } from "../constants/routes";

const ResetEmail = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 800px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });
  const location = useLocation();
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const { user, setLogin } = useAuthPersistStore();
  const navigate = useNavigate();

  const { mutate, isLoading } = useChangeEmail();
  const validationSchema = Yup.object({
    newEmail: Yup.string()
      .email("Email adresa nije ispravna")
      .required("Obavezno polje"),
    password: Yup.string().required("Obavezno polje"),
  });

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location?.search);

      const userId = searchParams.get("userId");
      const token = searchParams.get("token");

      if (userId && token) {
        setUserId(userId);
        setToken(token);
      }
    }
  }, [location]);

  return (
    <div className="create-profile">
      <Steps steps={4} activeStep={3} />
      <div className="create-profile_page">
        <div>
          <div className="create-profile_header">
            <div className="create-profile_header-content">
              <h1>Unesi novu email adresu</h1>
              <Paragraph variant="p2">{user.email}</Paragraph>
            </div>
            {isTablet && !isDesktop && <EmailIlustration />}
          </div>
          <Formik
            onSubmit={(values, actions) => {
              mutate(
                {
                  token: token,
                  userId: userId,
                  ...values,
                },
                {
                  onSuccess: (data) => {
                    // @ts-ignore
                    const { accessToken, user } = data;
                    setLogin(accessToken, user);
                    navigate(ROUTES.resetPasswordSuccess);
                  },
                  onError: (response) => {
                    actions.setFieldError(
                      "newEmail",
                      // @ts-ignore
                      response?.response?.data?.message ||
                        "Something went wrong"
                    );
                  },
                }
              );
            }}
            validationSchema={validationSchema}
            initialValues={{
              newEmail: "",
              password: "",
            }}
            enableReinitialize
          >
            <Form className="create-profile_form">
              <Input
                name="password"
                label="Unesite lozinku"
                type="password"
                placeholder="Unesite lozinku"
                autoFocus
              />
              <Input
                name="newEmail"
                label="Email"
                type="text"
                placeholder="Email"
                autoFocus
              />
              <Button
                variant="primary"
                label="Dalje"
                type="submit"
                hasLoader={true}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Form>
          </Formik>
        </div>
        {isDesktop && <EmailIlustration />}
      </div>
    </div>
  );
};

export default ResetEmail;

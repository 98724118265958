import React from "react";
import Button from "../button/Button";
import useAuthPersistStore from "../../store/authPersistStore";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useSubscriptionDetails } from "../../hooks/useSubscriptionDetails";
import { ReactComponent as CartIcon } from "../../assets/icons/cart.svg";

export const Promotion = () => {
  const { isAuthenticated } = useAuthPersistStore();
  const isDesktop = useMediaQuery({ query: "(min-width: 1040px)" });
  const navigate = useNavigate();
  const { data: subscriptionDetails } = useSubscriptionDetails({
    isAuthenticated,
  });
  const isActive =
    subscriptionDetails?.subscriptionType === "Monthly" ||
    subscriptionDetails?.subscriptionType === "Yearly";
  const location = useLocation();

  const showOnRoutes = [
    ROUTES.home,
    ROUTES.educators,
    ROUTES.aboutUs,
    ROUTES.content,
  ];

  if (isDesktop || !showOnRoutes.includes(location.pathname)) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <div className="serial_footer">
        <Button
          label="Odaberi plan"
          variant="primary"
          onClick={() => navigate(ROUTES.subscription)}
        />
        <Button
          label="Prijavi se"
          variant="secondary"
          onClick={() => navigate(ROUTES.login)}
        />
      </div>
    );
  }

  if (isAuthenticated && !isActive) {
    return (
      <div className="serial_footer">
        <Button
          label="Odaberi plan"
          variant="primary"
          leftIcon={<CartIcon />}
          onClick={() => navigate(ROUTES.subscription)}
        />
      </div>
    );
  }

  return null;
};

import Paragraph from "../components/paragraph/Paragraph";
import Button from "../components/button/Button";
import { ReactComponent as WelcomeIlustration } from "../assets/images/welcome-ilustration.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const SelectPlan = () => {
  const navigate = useNavigate();

  return (
    <div className="select-plan">
      <div className="select-plan_card">
        <WelcomeIlustration />
        <div className="select-plan_content">
          <h1>Odaberi plan</h1>
          <div>
            <div>
              <Paragraph variant="title">Već od</Paragraph>
              <div>
                <h2>19€</h2>
                <Paragraph variant="p2" className = "select_plan_paragraph">/Mesečno</Paragraph>
              </div>
            </div>
            <div>
              <Paragraph variant="title">Uštedi 10%</Paragraph>
              <div>
                <h2>95€</h2>
                <Paragraph variant="p2" className = "select_plan_paragraph">/6-meseci</Paragraph>
              </div>
            </div>
            <div>
              <Paragraph variant="title">Uštedi 20%</Paragraph>
              <div>
                <h2>190€</h2>
                <Paragraph variant="p2" className = "select_plan_paragraph">/Godišnje</Paragraph>
              </div>
            </div>
          </div>
          <Button
            variant="primary"
            onClick={() => navigate(ROUTES.subscription)}
            label="Pronađi odgovarajući plan"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectPlan;

import React from "react";
import { Form, Formik } from "formik";
import Paragraph from "../components/paragraph/Paragraph";
import Search from "../components/search/Search";
import Card from "../components/card/Card";
import { ContentFormatType } from "../types";
import { useNavigate } from "react-router-dom";
import { getImageRoute } from "../utils/getImageRoute";
import { ROUTES } from "../constants/routes";

interface AuthBannerInterface {
  categories: ContentFormatType[] | undefined;
}

const AuthedBanner = ({ categories }: AuthBannerInterface) => {
  const navigate = useNavigate();

  const items = categories?.filter(
    (item: ContentFormatType) => item.name !== "Meditacija"
  );

  return (
    <div className="auth-banner auth-banner--non-auth">
      <div className="auth-banner_banner">
        <h1>Šta gledaš danas?</h1>
        <Paragraph variant="p1">Napravi prvi korak</Paragraph>
        <Formik
          initialValues={{ search: "" }}
          onSubmit={(values) => {
            navigate(`${ROUTES.content}?search=${values?.search}`);
          }}
        >
          <Form className="auth-banner_search">
            <Search name="search" placeholder="Pronađi željeni sadržaj" />
          </Form>
        </Formik>
        <div className="auth-banner_grid">
          {items?.map((contentFormat: ContentFormatType) => (
            <Card
              key={contentFormat.name}
              label={contentFormat.name}
              onClick={() =>
                navigate(
                  `${ROUTES.content}?contentFormatIds=${contentFormat.id}`
                )
              }
              icon={
                <img
                  src={getImageRoute(contentFormat.icon)}
                  alt={contentFormat.name}
                />
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AuthedBanner;

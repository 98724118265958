/* eslint-disable react-hooks/exhaustive-deps */
import Paragraph from "../components/paragraph/Paragraph";
import Search from "../components/search/Search";
import { Form, Formik } from "formik";
import Filter from "../components/filter/Filter";
import ContentCategory from "../sections/ContentCategory";
import {
  ContentFormatType,
  EducatorType,
  SerialType,
  TagType,
} from "../types";
import { useEducators } from "../hooks/useEducators";
import { useTags } from "../hooks/useTags";
import { useSerialsSearch } from "../hooks/useSerialsSearch";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "../components/spinner/Spinner";
import isEmpty from "lodash.isempty";
import { ROUTES } from "../constants/routes";
import Button from "../components/button/Button";
import { useSortedContentFormats } from "../hooks/useSortedContentFormats";

interface QueryParamValues {
  tagIds: number[] | [];
  contentFormatIds: number[] | [];
  educatorIds: number[] | [];
  perPage: number;
  pageNumber: number;
  search: string;
}

interface ResponseInterface {
  pageNumber: number;
  perPage: number;
  serials: SerialType[];
  totalCount: number;
}

const Content = () => {
  const [queryParamValues, setQueryParamValues] =
    useState<QueryParamValues | null>(null);
  const [pagination, setPagination] = useState({ perPage: 10, pageNumber: 1 });
  const [response, setResponse] = useState<ResponseInterface | {}>({});
  const { data: contentFormats, isLoading: isContentFormatsLoading } =
    useSortedContentFormats();
  const { data: educators, isLoading: isEducatorsLoading } = useEducators();
  const { data: tags, isLoading: isTagsLoading } = useTags();
  const {
    mutate,
    response: searchResponse,
    isLoading: isSearchLoading,
  } = useSerialsSearch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isLoading =
    isContentFormatsLoading ||
    isTagsLoading ||
    isSearchLoading ||
    isEducatorsLoading;

  const getQueryParamValue = (key: string) => {
    if (queryParams.has(key)) {
      const values = queryParams
        ?.get(key)
        ?.split(",")
        .map((item) => Number(item));
      // @ts-ignore
      return [...values];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (location.search) {
      const extractedValues = {
        tagIds: getQueryParamValue("tagIds"),
        search: queryParams.get("search") || "",
        contentFormatIds: getQueryParamValue("contentFormatIds"),
        educatorIds: getQueryParamValue("educatorIds"),
        perPage: queryParams.get("perPage")
          ? Number(queryParams.get("perPage"))
          : pagination?.perPage,
        pageNumber: queryParams.get("pageNumber")
          ? Number(queryParams.get("pageNumber"))
          : pagination?.pageNumber,
      };

      setQueryParamValues({
        ...extractedValues,
      });
    } else {
      // @ts-ignore
      setQueryParamValues({
        perPage: pagination?.perPage,
        pageNumber: pagination?.pageNumber,
      });
    }
  }, [location.search]);

  useEffect(() => {
    // @ts-ignore
    if (queryParamValues) {
      setQueryParamValues({
        ...queryParamValues,
        pageNumber: pagination?.pageNumber,
      });
    }
  }, [pagination?.pageNumber]);

  useEffect(() => {
    queryParamValues && mutate(queryParamValues);
  }, [queryParamValues]);

  useEffect(() => {
    searchResponse && setResponse(searchResponse);
  }, [searchResponse]);

  // @ts-ignore
  if (isLoading && isEmpty(response?.serials)) {
    return <Spinner />;
  }

  return (
    <div className="content">
      <div>
        <div className="content_header">
          <h1>Sadržaj</h1>
          {/* @ts-ignore */}
          <Paragraph variant="p1">{response?.totalCount || 0}</Paragraph>
        </div>

        <Formik
          initialValues={{
            search: queryParamValues?.search || "",
            tagIds: queryParamValues?.tagIds || [],
            contentFormatIds: queryParamValues?.contentFormatIds || [],
            educatorIds: queryParamValues?.educatorIds || [],
          }}
          enableReinitialize
          onSubmit={(values) => {
            const queryParams = Object.entries(values)
              .filter(
                ([_, value]) =>
                  value !== null &&
                  value !== undefined &&
                  value !== "" &&
                  (Array.isArray(value) ? value.length > 0 : true)
              )
              .map(
                ([key, value]) =>
                  `${key}=${Array.isArray(value) ? value.join(",") : value}`
              )
              .join("&");

            setPagination({
              perPage: pagination?.perPage,
              pageNumber: 1,
            });

            // Use navigate to change the URL without triggering a re-render
            navigate(
              `${ROUTES.content}${queryParams ? `?${queryParams}` : ""}`,
              {
                replace: true,
              }
            );
          }}
        >
          <Form className="content_form">
            <Search name="search" showIcon={true} />
            <div className="content_filters">
              {tags && (
                <Filter
                  name="tagIds"
                  label="Teme"
                  items={tags?.map((tag: TagType) => {
                    return {
                      label: tag.name,
                      value: tag.id,
                    };
                  })}
                />
              )}
              {contentFormats && (
                <Filter
                  name="contentFormatIds"
                  label="Kategorije"
                  items={contentFormats?.map((tag: ContentFormatType) => {
                    return {
                      label: tag.name,
                      value: tag.id,
                    };
                  })}
                />
              )}
              {educators && (
                <Filter
                  name="educatorIds"
                  label="Edukatori"
                  items={educators?.map((educator: EducatorType) => {
                    return {
                      value: educator?.id,
                      label: educator?.fullName,
                    };
                  })}
                />
              )}
              <Button
                type="submit"
                label="Primeni filtere"
                variant="primary"
                hasLoader={true}
                isLoading={isSearchLoading}
                disabled={isSearchLoading}
              />
            </div>
          </Form>
        </Formik>
      </div>

      <ContentCategory
        // @ts-ignore
        items={response?.serials}
        // @ts-ignore
        totalCount={response?.totalCount}
        pagination={pagination}
        setPagination={setPagination}
        isLoading={isSearchLoading}
      />
    </div>
  );
};

export default Content;

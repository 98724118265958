import Input from "../components/input/Input";
import * as Yup from "yup";
import Button from "../components/button/Button";
import { Formik, Form } from "formik";
import Link from "../components/Link/Link";
import Checkbox from "../components/checkbox/Checkbox";
// import { ReactComponent as GoogleIcon } from "../assets/icons/google.svg";
import Steps from "../components/steps/Steps";
import Paragraph from "../components/paragraph/Paragraph";
import { ReactComponent as RegisterIlustration } from "../assets/images/register-ilustration.svg";
import { useMediaQuery } from "react-responsive";
import { useRegister } from "../hooks/useRegister";
import { ROUTES } from "../constants/routes";
import { useNavigate } from "react-router-dom";
import useSubscriptionStore from "../store/subscriptionStore";
import { REFERRAL_PARAM, REFERRAL_SEMINAR } from "../constants/url-params";



const Register = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const referralValue = searchParams.get(REFERRAL_PARAM);
  if (referralValue === REFERRAL_SEMINAR) {
    // TODO: fore da se posle, na koraku za odabir plana, prikaze samo 2 meseca
  }



  const isTablet = useMediaQuery({ query: "(min-width: 800px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();
  const { mutate, isLoading } = useRegister();
  const { subscriptionType, setSubscriptionType } = useSubscriptionStore();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email adresa nije ispravna")
      .required("Obavezno polje"),
    password: Yup.string()
      .required("Obavezno polje")
      .min(8, "Mora imati najmanje 8 karaktera")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
        "Najmanje 8 karaktera (Obavezno veliko i malo slovo, broj)"
      ),
    policy: Yup.bool()
      .oneOf([true], "Obavezno polje")
      .required("Obavezno polje"),
  });

  return (
    <div className="register">
      <Steps steps={4} activeStep={1} />
      <div className="register_page">
        <div className="register_page-content">
          <div className="register_header">
            <div className="register_header-content">
              <h1>Registruj se</h1>
              <Paragraph variant="p2">
                Tvoj put ka boljem životu kreće ovde.
              </Paragraph>
            </div>

            {isTablet && !isDesktop && <RegisterIlustration />}
          </div>

          <Formik
            onSubmit={(values, actions) =>
              mutate(
                {
                  email: values?.email,
                  password: values?.password,
                  subscriptionType,
                  isSubscribedToNewsletter: values?.isSubscribedToNewsletter,
                },
                {
                  onSuccess: () => {
                    navigate(ROUTES.registerSuccess, {
                      state: {
                        email: values?.email,
                      },
                    });
                    setSubscriptionType({ subscriptionType: null });
                  },
                  onError: (response) => {
                    actions.setFieldError(
                      "email",
                      // @ts-ignore
                      response?.response?.data?.message ||
                        "Something went wrong"
                    );
                  },
                }
              )
            }
            validationSchema={validationSchema}
            initialValues={{
              email: "",
              password: "",
              isSubscribedToNewsletter: true,
              policy: false,
            }}
          >
            <Form className="register_form">
              <Input
                name="email"
                label="Email"
                placeholder="Unesite email adresu"
                type="email"
              />
              <Input
                name="password"
                label="Odaberite lozinku"
                type="password"
                placeholder="Unesite željenu lozinku"
              />
              <Input
                name="confirmPassword"
                label="Ponovite lozinku"
                type="password"
                placeholder="Unesite odabranu lozinku ponovo"
              />

              <Checkbox
                name="policy"
                label="Slažem se sa"
                link={{
                  label: "uslovima korišćenja",
                  // @ts-ignore
                  onClick: () => navigate(ROUTES.legal),
                }}
              />
              <Checkbox
                name="isSubscribedToNewsletter"
                label="Prijavi se na newsletter"
              />

              <div className="register_cta">
                <Button
                  variant="primary"
                  label="Registruj se"
                  type="submit"
                  hasLoader={true}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
                {/* <h3>Ili nastavite uz</h3>
                <div className="register_social">
                  <Button
                    leftIcon={<GoogleIcon />}
                    label="Google"
                    variant="secondary"
                    type="button"
                  />
                </div> */}
              </div>
            </Form>
          </Formik>
          <div className="register_link">
            <h3>Već imaš nalog?</h3>
            <Link
              label="Prijavi se"
              variant="primary"
              onClick={() => navigate(ROUTES.login)}
            />
          </div>
        </div>
        {isDesktop && <RegisterIlustration />}
      </div>
    </div>
  );
};

export default Register;

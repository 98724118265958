import { useLocation } from "react-router-dom";

// @ts-ignore
const Main = ({ children }) => {
  const location = useLocation();

  return (
    <main
      className={
        !(location.pathname === "/" || location.pathname === "/home")
          ? "main"
          : "main--home"
      }
    >
      {children}
    </main>
  );
};

Main.propTypes = {};

export default Main;

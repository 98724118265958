import Input from "../components/input/Input";
import * as Yup from "yup";
import Button from "../components/button/Button";
import { Formik, Form } from "formik";
import Steps from "../components/steps/Steps";
import { ReactComponent as ProfileIlustration } from "../assets/images/profile-ilustration.svg";
import { useMediaQuery } from "react-responsive";
import { useCountries } from "../hooks/useCountries";
import Autocomplete from "../components/autocomplete/Autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCreateProfile } from "../hooks/useCreateProfile";
import { convertCountryToIso3Code } from "../utils/convertCountryToIso3Code";
import { toast } from "react-toastify";
import { convertToIso8601 } from "../utils/convertToIso8601";
import useAuthPersistStore from "../store/authPersistStore";
import { ROUTES } from "../constants/routes";

const CreateProfile = () => {
  const [subscriptionType, setSubscriptionType] = useState<String | null>(null);
  const isTablet = useMediaQuery({ query: "(min-width: 800px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });
  const { mutate, isLoading } = useCreateProfile();
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const { setLogin } = useAuthPersistStore();
  const { countries } = useCountries();
  const location = useLocation();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Obavezno polje"),
    dateOfBirth: Yup.string()
      .required("Obavezno polje")
      .matches(
        /^\d{2}.\d{2}.\d{4}$/,
        "Mora biti u formatu dd.mm.yyyy (npr. 01.01.2023)"
      ),
    // country: Yup.string().required("Obavezno polje"),
    // city: Yup.string().required("Obavezno polje"),
  });

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location?.search);

      const userId = searchParams.get("userId");
      const token = searchParams.get("token");
      const subscriptionType = searchParams.get("subscriptionType");

      if (userId && token) {
        setUserId(userId);
        setToken(token);
      }

      if (subscriptionType) {
        setSubscriptionType(subscriptionType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="create-profile">
      <Steps steps={4} activeStep={3} />
      <div className="create-profile_page">
        <div>
          <div className="create-profile_header">
            <div className="create-profile_header-content">
              <h1>Završi kreiranje profila</h1>
            </div>
            {isTablet && !isDesktop && <ProfileIlustration />}
          </div>
          <Formik
            onSubmit={(values, actions) => {
              // const countryList = Object.values(countries);
              const { dateOfBirth, ...rest } = values;
              // const { country, dateOfBirth, ...rest } = values;
              // const iso3Code = convertCountryToIso3Code(country);

              // if (
              //   !countryList.some(
              //     (item) => item.toLowerCase() === country.toLowerCase()
              //   )
              // ) {
              //   toast("Država nije ispravna. Molimo vas unesite je ispravno.");
              //   return;
              // }

              mutate(
                {
                  token: token || undefined,
                  userId: userId || undefined,
                  // country: iso3Code,
                  dateOfBirth: convertToIso8601(dateOfBirth),
                  ...rest,
                },
                {
                  onSuccess: (data) => {
                    // @ts-ignore
                    const { accessToken, user } = data;
                    setLogin(accessToken, user);

                    if (subscriptionType) {
                      navigate(ROUTES.confirmPlan, {
                        state: { subscriptionType },
                      });
                      return;
                    }

                    navigate(ROUTES.createProfileSuccess);
                  },
                  onError: (response) => {
                    actions.setFieldError(
                      "fullName",
                      // @ts-ignore
                      response?.response?.data?.message ||
                        "Something went wrong"
                    );
                  },
                }
              );
            }}
            validationSchema={validationSchema}
            initialValues={{
              fullName: "",
              dateOfBirth: "",
              // country: "",
              // city: "",
            }}
            enableReinitialize
          >
            <Form className="create-profile_form">
              <Input
                name="fullName"
                label="Ime i prezime"
                placeholder="Unesite ime i prezime"
                type="text"
              />
              <Input
                name="dateOfBirth"
                label="Datum rođenja"
                placeholder="Unesite datum rođenja"
                type="text"
                mask={"dd.mm.yyyy"}
                replacement={{ d: /\d/, m: /\d/, y: /\d/ }}
              />
              {/* <div className="create-profile_form-place">
                <Autocomplete
                  name="country"
                  label="Država"
                  placeholder="Unesite Državu"
                  type="text"
                  items={Object.values(countries)}
                />
                <Input
                  name="city"
                  label="Mesto"
                  placeholder="Unesite mesto"
                  type="text"
                />
              </div> */}
              <Button
                variant="primary"
                label="Kreiraj profil"
                type="submit"
                hasLoader={true}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Form>
          </Formik>
        </div>
        {isDesktop && <ProfileIlustration />}
      </div>
    </div>
  );
};

export default CreateProfile;

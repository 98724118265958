import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import Sidebar from "../sidebar/Sidebar";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Loggout } from "../../assets/icons/logout.svg";
import { ReactComponent as Account } from "../../assets/icons/account.svg";

import HeaderDropdownMenu from "./HeaderDropdown";
import HeaderProfile from "./HeaderProfile";
import Button from "../button/Button";
import useAuthPersistStore from "../../store/authPersistStore";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentFormatType } from "../../types";
import { getImageRoute } from "../../utils/getImageRoute";
import { ROUTES } from "../../constants/routes";
import { useSortedContentFormats } from "../../hooks/useSortedContentFormats";
import useLogout from "../../hooks/useLogout";

const Header = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const isTablet = useMediaQuery({ query: "(min-width: 480px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1040px)" });
  const { isAuthenticated, user } = useAuthPersistStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleLogout } = useLogout();

  const closeSidebar = () => {
    setIsSideBarOpen(false);
  };

  const { data: sortedContentFormats } = useSortedContentFormats();

  useEffect(() => {
    if (isAuthenticated && !user?.userProfile?.fullName) {
      navigate(ROUTES.createProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (location) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  if (isDesktop) {
    return (
      <>
        <header className="header header--desktop">
          <div className="header_container">
            <div className="header_left">
              <div
                className="header_logo"
                onClick={() => navigate(ROUTES.home)}
              >
                <Logo />
              </div>
              <nav className="header_nav">
                <ul className="header_nav-list">
                  <HeaderDropdownMenu
                    label="Istraži"
                    items={[
                      {
                        link: ROUTES.home,
                        label: "Početna",
                      },
                      {
                        link: ROUTES.subscription,
                        label: "Odaberi plan",
                      },
                    ]}
                  />
                  <HeaderDropdownMenu
                    label="Sadržaj"
                    items={sortedContentFormats?.map(
                      (contentFormat: ContentFormatType) => {
                        return {
                          link: `${ROUTES.content}?contentFormatIds=${contentFormat.id}`,
                          label: contentFormat.name,
                          imageUrl: getImageRoute(contentFormat.icon),
                        };
                      }
                    )}
                  />
                  <HeaderDropdownMenu
                    label="Kompanija"
                    items={[
                      {
                        link: ROUTES.aboutUs,
                        label: "O nama",
                      },
                      {
                        link: ROUTES.educators,
                        label: "Edukatori",
                      },
                    ]}
                  />
                </ul>
              </nav>
            </div>
            <div className="header_sidebar">
              <div className="header-sidebar_cta">
                <Search onClick={() => navigate(ROUTES.content)} />
                {isAuthenticated ? (
                  <HeaderProfile
                    items={[
                      {
                        link: ROUTES.profile,
                        label: "Moj profil",
                        icon: <Account />,
                      },
                      {
                        link: ROUTES.login,
                        label: "Odjavi se",
                        icon: <Loggout />,
                        onClick: handleLogout,
                      },
                    ]}
                  />
                ) : (
                  <div className="header-sidebar_cta-buttons">
                    <Button
                      onClick={() => navigate(ROUTES.register)}
                      label="Registruj se"
                      variant="primary"
                    />
                    <Button
                      onClick={() => navigate(ROUTES.login)}
                      label="Prijavi se"
                      variant="secondary"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }

  return (
    <>
      <header
        className={`header ${
          isSideBarOpen ? "header--open" : "header--closed"
        }`}
      >
        <div className="header_logo" onClick={() => navigate(ROUTES.home)}>
          <Logo />
        </div>
        <div className="header_sidebar">
          {isTablet && (
            <div className="header-sidebar_cta">
              <Search onClick={() => navigate(ROUTES.content)} />
              {isAuthenticated && <HeaderProfile />}
            </div>
          )}
          <button
            className="header_trigger"
            onClick={() => setIsSideBarOpen(!isSideBarOpen)}
          >
            <Menu />
          </button>
        </div>
      </header>
      <Sidebar isOpen={isSideBarOpen} close={closeSidebar} />
    </>
  );
};

export default Header;

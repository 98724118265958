import React, { InputHTMLAttributes } from "react";
import { useField } from "formik";
import { ReactComponent as ConfirmIcon } from "../../assets/icons/confirm.svg";
import { trim } from "../../utils/trim";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  name: string;
  label: string;
  link?: {
    label: string;
  };
}

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  name,
  label,
  link,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <div
      className={trim(
        `checkbox ${!!field.value ? "checkbox--checked" : ""} ${
          meta.error ? "checkbox--error" : ""
        }`
      )}
    >
      <label className="checkbox_inner">
        <input
          checked={!!field?.value}
          className="checkbox_field"
          type="checkbox"
          {...field}
          {...props}
        />

        {meta?.error && (
          <ReactTooltip
            isOpen={!!meta.error}
            id={name}
            place="top"
            content={meta.error}
            style={{
              color: "#fb7070",
              fontWeight: 600,
              fontFamily: "Montserrat",
              backgroundColor: "#2f2f2f",
            }}
          />
        )}
        <div data-tooltip-id={name} className="checkbox_indicator">
          <ConfirmIcon />
        </div>

        {label && (
          <p className="checkbox_label">
            {label}{" "}
            {link && (
              <a {...link} className="checkbox_link">
                {link.label}
              </a>
            )}
          </p>
        )}
      </label>
    </div>
  );
};

export default Checkbox;

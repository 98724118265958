import { useQuery } from "@tanstack/react-query";

import { GET } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useMyContent = ({isAuthenticated}: {
  isAuthenticated: boolean
}) => {
  const query = useQuery<number[], Error, number[]>({
    // @ts-ignore
    queryKey: ["MY-CONTENT-SERIAL-IDS"],
    queryFn: async () => {
      const data = await GET(ENDPOINTS.myContent);
      return data;
    },
    enabled: !!isAuthenticated,
  });

  return { ...query };
};

import { FC } from "react";
import { ContentFormatType } from "../../types";
import { getImageRoute } from "../../utils/getImageRoute";
import Paragraph from "../paragraph/Paragraph";
import { trim } from "../../utils/trim";

interface BadgeProps {
  contentFormat: ContentFormatType;
  variant: "filled" | "outlined" | "blurred";
  size: "sm" | "md";
  onClick?: () => void;
  noLabel?: boolean;
}

const Badge: FC<BadgeProps> = ({
  contentFormat,
  variant,
  size,
  onClick,
  noLabel = false,
}) => {
  const { name } = contentFormat;

  return (
    <div
      className={trim(
        `badge ${variant ? `badge--${variant}` : ""} ${
          size ? `badge--${size}` : ""
        }`
      )}
      onClick={onClick}
    >
      <img src={getImageRoute(contentFormat.icon)} alt={contentFormat.name} />

      {!noLabel && <Paragraph variant="p3">{name}</Paragraph>}
    </div>
  );
};

export default Badge;

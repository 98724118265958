import React, { ReactNode } from "react";
import { trim } from "../../utils/trim";

interface ParagraphProps {
  children: ReactNode;
  variant?: string;
  className?: string;
  onClick?: () => void;
}

const Paragraph: React.FC<ParagraphProps> = ({
  children,
  variant,
  className,
  onClick,
}) => {
  return (
    <p
      className={trim(
        `paragraph ${variant ? `paragraph--${variant}` : ""} ${className || ""}`
      )}
      onClick={onClick}
    >
      {children}
    </p>
  );
};

export default Paragraph;

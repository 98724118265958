import Link from "../components/Link/Link";
import { ReactComponent as ArrowLongRight } from "../assets/icons/arrow-long-right.svg";
import Paragraph from "../components/paragraph/Paragraph";
import Carousel from "../components/carousel/Carousel";
import { ContentFormatType, SerialType } from "../types";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import Empty from "../components/empty/Empty";
import { getImageRoute } from "../utils/getImageRoute";
import { useSerialsByFormat } from "../hooks/useFeaturedSerials";
import { ROUTES } from "../constants/routes";

interface SerialSlidesProps {
  contentFormat: ContentFormatType;
  options?: object;
  component: (item: SerialType) => React.ReactNode;
  emptySlide: {};
}

const SerialSlides = ({
  emptySlide,
  contentFormat,
  options,
  component,
}: SerialSlidesProps) => {
  const navigate = useNavigate();

  // @ts-ignore
  const { data } = useSerialsByFormat({ id: contentFormat?.id });

  if (!contentFormat) {
    return null;
  }

  if (!contentFormat) {
    return null;
  }

  return (
    <div className="episode-slides">
      <div className="episode-slides_header">
        <div className="episode-slides_title">
          <h2>{contentFormat?.name}</h2>
        </div>
        <Link
          onClick={() =>
            navigate(`${ROUTES.content}?contentFormatIds=${contentFormat?.id}`)
          }
          variant="secondary"
          label="Više"
          icon={<ArrowLongRight />}
        />
      </div>
      <Paragraph variant="p2" className="episode-slides_description">
        {contentFormat?.description}
      </Paragraph>
      <div className="episode-slides_carousel-wrap">
        {isEmpty(data) ? (
          // @ts-ignore
          <Empty emptySlide={emptySlide} />
        ) : (
          <Carousel
            items={data}
            component={(item: SerialType) => (
              <div
                className="episode-slides_slide"
                onClick={() =>
                  navigate(`${ROUTES.serial}/${item?.id}`, {
                    state: { ...item },
                  })
                }
              >
                {component(item)}
              </div>
            )}
            options={{
              ...options,
            }}
          />
        )}
      </div>
      {contentFormat && (
        <div className="episode-slides_background">
          <img
            src={getImageRoute(contentFormat?.icon)}
            alt={contentFormat?.name}
          />
        </div>
      )}
    </div>
  );
};

export default SerialSlides;

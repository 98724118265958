import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Link from "../components/Link/Link";
import Paragraph from "../components/paragraph/Paragraph";
import { useWindowSize } from "@uidotdev/usehooks";
import { SerialType } from "../types";
import Slider from "../components/Slider/Slider";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowLongLeft } from "../assets/icons/arrow-long-left.svg";
import { getImageRoute } from "../utils/getImageRoute";
import { useEducatorContent } from "../hooks/useEducatorContent";
import { useEducator } from "../hooks/useEducator";
import Spinner from "../components/spinner/Spinner";
import { ROUTES } from "../constants/routes";

function openInNewTab(url: string) {
  var newTab = window.open(url, "_blank");
  newTab && newTab.focus();
}

const Educator = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 480px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1040px)" });
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const size = useWindowSize();
  const { id } = useParams();
  const { state } = useLocation();
  const { data: educatorContent, isLoading: isEducatorContentLoading } =
    useEducatorContent(id);
  const { data: educator, isLoading: isEducatorLoading } = useEducator(id);
  const navigate = useNavigate();

  const currentEducator = educator || state;

  const getSliderPaddings = () => {
    if (isDesktop) {
      return 0;
    }

    if (isTablet) {
      return 60;
    }

    return 30;
  };

  const getSlideWidth = (orientation: string) => {
    if (orientation === "vertical") {
      if (isDesktop) {
        return containerWidth * 0.2395;
      }

      if (isTablet) {
        return containerWidth * 0.325;
      }

      return containerWidth * 0.42;
    }

    if (orientation === "horizontal") {
      if (isDesktop) {
        return containerWidth * 0.493;
      }

      if (isTablet) {
        return containerWidth * 0.59;
      }

      return containerWidth * 0.75;
    }
  };

  const getSpaceBetweenSlides = () => {
    if (isDesktop) {
      return 18;
    }

    if (isTablet) {
      return 12;
    }

    return 6;
  };

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef?.current?.offsetWidth || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, size]);

  if (isEducatorContentLoading && isEducatorLoading && !state) {
    return <Spinner />;
  }

  return (
    <div className="c-educator" ref={containerRef}>
      <div className="c-educator__header">
        <div className="c-educator__cta">
          <div
            className="c-educator__back"
            onClick={() => navigate(ROUTES.educators)}
          >
            <button>
              <ArrowLongLeft />
            </button>
            <Link label="Ostali edukatori" variant="primary" />
          </div>
          <div className="c-educator__information">
            <h1>{currentEducator?.fullName}</h1>
            <Paragraph variant="title">{currentEducator?.profession}</Paragraph>
          </div>
        </div>
        <img
          src={getImageRoute(currentEducator?.profileImage)}
          alt={currentEducator?.profileImage}
        />
      </div>
      <div className="c-educator__biography">
        <h2>Biografija</h2>
        <Paragraph variant="p2">{currentEducator?.description}</Paragraph>
        <div className="c-educator__more">
          <h3>Saznajte više</h3>
          <ul>
            {currentEducator?.links?.websiteLink && (
              <li>
                <Link
                  variant="primary"
                  label="Website"
                  onClick={() =>
                    openInNewTab(currentEducator?.links?.websiteLink)
                  }
                />
              </li>
            )}
            {currentEducator?.links?.youtubeLink && (
              <li>
                <Link
                  variant="primary"
                  label="Youtube"
                  onClick={() =>
                    openInNewTab(currentEducator?.links?.youtubeLink)
                  }
                />
              </li>
            )}
            {currentEducator?.links?.instagramLink && (
              <li>
                <Link
                  variant="primary"
                  label="Instagram"
                  onClick={() =>
                    openInNewTab(currentEducator?.links?.instagramLink)
                  }
                />
              </li>
            )}
            {currentEducator?.links?.tiktokLink && (
              <li>
                <Link
                  variant="primary"
                  label="TikTok"
                  onClick={() =>
                    openInNewTab(currentEducator?.links?.tiktokLink)
                  }
                />
              </li>
            )}
          </ul>
        </div>
      </div>
      {educatorContent && (
        <Slider
          educator={educator}
          emptySlide={{
            type: "vertical",
            width: getSlideWidth("vertical"),
            aspectRatio: "3/4",
          }}
          component={(serial: SerialType) => (
            <img
              style={{
                width: getSlideWidth("vertical"),
                aspectRatio: "3/4",
              }}
              src={getImageRoute(serial?.verticalCoverImage)}
              alt={serial.title}
              onClick={() => {
                navigate(`${ROUTES.serial}/${serial?.id}`, {
                  state: { ...serial },
                });
              }}
            />
          )}
          items={educatorContent}
          options={{
            gap: getSpaceBetweenSlides(),
            padding: getSliderPaddings(),
            perMove: 1,
            perPage: 1,
          }}
        />
      )}
    </div>
  );
};

Educator.propTypes = {};

export default Educator;

import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIlustration } from "../assets/images/success-ilustration.svg";
import Button from "../components/button/Button";
import { ROUTES } from "../constants/routes";

const ChangeProfileSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="create-profile-success">
      <div className="create-profile-success_page">
        <SuccessIlustration />
        <div className="create-profile-success_content">
          <div className="create-profile-success_header">
            <h1>Lični podaci uspešno promenjeni</h1>
          </div>
          <div className="create-profile-success_cta">
            <Button
              label="Nazad"
              variant="secondary"
              onClick={() => navigate(ROUTES.home)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeProfileSuccess;

import Paragraph from "../paragraph/Paragraph";
import { ReactComponent as CloseIcon } from "../../assets/icons/cancel.svg";
import { ReactComponent as PencilIcon } from "../../assets/icons/write.svg";

import Button from "../button/Button";
import { Form, Formik } from "formik";
import Input from "../input/Input";
import { useState } from "react";
import { useCreateComment } from "../../hooks/useCreateComment";
import useAuthPersistStore from "../../store/authPersistStore";
import { convertFromISO8601 } from "../../utils/convertToIso8601";

interface CommentsInterface {
  isOpen: Boolean;
  handleClose: () => void;
  refetch: () => void;
  comments: {
    time: string;
    comment: string;
    user: string;
  }[];
  id: string;
}

const Comments = ({
  handleClose,
  comments,
  refetch,
  id,
}: CommentsInterface) => {
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);
  const { mutate, isLoading } = useCreateComment();
  const { isAuthenticated } = useAuthPersistStore();
  return (
    <div className="comments__wrap">
      <div className="comments__comments">
        <div className="comments__header">
          <div className="comments__title">
            <h2>Komentari</h2>
            <Paragraph variant="p2">{comments?.length || 0}</Paragraph>
          </div>
          <button className="comments__close" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="comments__body">
          <ul className="comments__list">
            {comments?.map(
              // @ts-ignore
              (item: { createdAt: string; content: string; user: string }) => {
                return (
                  <li key={item.createdAt} className="comments__list-item">
                    <Paragraph variant="p2">{item?.content}</Paragraph>
                    <div>
                      <h3>
                        {/* @ts-ignore */}
                        {item?.user?.userProfile?.fullName}{" "}
                        {item.createdAt && (
                          <span>{` • ${convertFromISO8601(
                            item.createdAt
                          )}`}</span>
                        )}
                      </h3>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        </div>
        <div className="comments__cta">
          <div
            className={`comments__form-wrap ${
              isCommentFormOpen
                ? "comments__form-wrap--open"
                : "comments__form-wrap--closed"
            }`}
          >
            <Formik
              onSubmit={(values, helper) =>
                mutate(
                  { id, ...values },
                  {
                    onSuccess: () => {
                      refetch();
                      setIsCommentFormOpen(false);
                    },
                    onError: () => {
                      helper.setFieldError(
                        "content",
                        "Nije moguce ostaviti komentar."
                      );
                    },
                  }
                )
              }
              initialValues={{
                content: "",
              }}
              enableReinitialize
            >
              <Form className="comments__form">
                <Input
                  name="content"
                  label="Unesite komentar"
                  placeholder="Unesite komentar"
                  type="text"
                />
                <div>
                  <Button
                    variant="error"
                    label="Poništi"
                    type="button"
                    onClick={() => {
                      setIsCommentFormOpen(false);
                    }}
                  />
                  <Button
                    variant="primary"
                    label="Pošalji"
                    type="submit"
                    disabled={!isAuthenticated || isLoading}
                    hasLoader={true}
                    isLoading={isLoading}
                  />
                </div>
              </Form>
            </Formik>
          </div>
          <Button
            variant="primary"
            leftIcon={<PencilIcon />}
            label="Ostavi komentar"
            disabled={!isAuthenticated}
            onClick={() => setIsCommentFormOpen(true)}
          />
        </div>
      </div>
    </div>
  );
};

Comments.propTypes = {};

export default Comments;

export interface TagType {
  description: string;
  id: string;
  name: string;
  title: string;
  tagImage: string;
}

export interface ContentFormatType {
  description: string;
  id: string;
  navigationImage: string;
  title: string;
  landingImage: string;
}

export interface SerialType {
  id: string;
  description: string;
  educatorIds: string;
  isPublished: boolean;
  soldStandalone: boolean;
  tagIds: string;
  title: string;
  trailerYoutubeLink: string;
  type: string;
  horizontalCoverImage: string;
  verticalCoverImage: string;
  category: string;
  isFeatured: boolean;
  educator: {
    id: string,
    fullName: string,
  },
  contentFormatId: string,
  episodeCount: number,
  contentFormat: ContentFormatType,
}

export interface EducatorType {
  id: string;
  fullName: string;
  profileImage: string;
  description: string;
  profession: string;
}

export interface EpisodeType {
  id: string;
  description: string;
  serialId: string;
  tagIds: string;
  title: string;
  vimeoLink: string;
  coverImage: string;
  category: string;
}

export interface ContentFormatType {
  name: string;
  description: string;
  icon: string;
  id: string;
}

export interface SubscriptionDetails {
  createdAt: string;
  updatedAt: string;
  subscriptionAtRisk: boolean;
  subscriptionType: string;
  userId: string;
  currentPeriodActiveUntil: string;
  scheduledToCancel: boolean;
}

export interface SubscriptionPriceType {
  amount: number,
  currency: string,
}

export enum PlanTypes {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  TwoMonths = 'TwoMonths',
  ThreeMonths = 'ThreeMonths',
  SixMonths = 'SixMonths'
}

import { useMutation } from "@tanstack/react-query";
import { DataOrParams, POST } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";
import { useState } from "react";

export const useSerialsSearch = () => {
    const [response, setResponse] = useState();

  const mutation = useMutation({
    mutationFn: async (payload: DataOrParams) => {
      const data = await POST(ENDPOINTS.serialsSearch, payload);
      return data;
    },
    onSuccess: (data) => {
      // @ts-ignore
      setResponse(data)
    },
    onError: () => {
      setResponse(undefined)
    }
  });

  return { ...mutation, response };
};

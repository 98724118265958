import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as TikTokIcon } from "../../assets/icons/tiktok.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";

import Paragraph from "../paragraph/Paragraph";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { istrazi, kompanija } from "../../constants/menu";
import { ContentFormatType } from "../../types";
import { ROUTES } from "../../constants/routes";
import { useSortedContentFormats } from "../../hooks/useSortedContentFormats";

const Footer = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1040px)" });
  const navigate = useNavigate();
  const { data: sortedContentFormats } = useSortedContentFormats();

  if (isDesktop) {
    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="footer_header">
            <Logo />
            <Paragraph variant="p3" className="footer_description">
              Najveća platforma praktičnog znanja na Balkanu iz oblasti
              neurobiologije, psihologije i duhovnosti.
              Nauči mehanizme promene i uz protokole i prakse
              započni putovanje ka ličnom rastu i razvoju
              prijavom na našu platformu.
            </Paragraph>
            <h3 className="footer_follow">
              Zaprati nas na društvenim mrežama
            </h3>
            <div className="footer_social">
              <TikTokIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.tiktok.com/@neoummedia", "_blank")
                }
              />
              <InstagramIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.instagram.com/neoummedia/", "_blank")
                }
              />
              <YoutubeIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCMXp65nlDIXgJ92GcIXkfWg",
                    "_blank"
                  )
                }
              />
            </div>
            <Paragraph variant="p3" className="footer_copyright">
              Sva prava zadržana © 2023 NeoUm Media
            </Paragraph>
          </div>
          <div className="footer_lists">
            <ul className="footer_list">
              <li className="footer_list-header">Istraži</li>
              <li className="footer_list-item" onClick={() => navigate(`/`)}>
                Početna
              </li>
              <li
                className="footer_list-item"
                onClick={() => navigate(ROUTES.subscription)}
              >
                Odaberi plan
              </li>
            </ul>
            <ul className="footer_list">
              <li className="footer_list-header">Sadržaj</li>
              {sortedContentFormats?.map((contentFormat: ContentFormatType) => (
                <li
                  className="footer_list-item"
                  onClick={() =>
                    navigate(
                      `${ROUTES.content}?contentFormatIds=${contentFormat.id}`
                    )
                  }
                  key={contentFormat.name}
                >
                  {contentFormat.name}
                </li>
              ))}
            </ul>
            <ul className="footer_list">
              <li className="footer_list-header">Kompanija</li>
              <li
                className="footer_list-item"
                onClick={() => navigate(ROUTES.aboutUs)}
              >
                O nama
              </li>
              <li
                className="footer_list-item"
                onClick={() => navigate(ROUTES.educators)}
              >
                Edukatori
              </li>
              <li
                className="footer_list-item"
                onClick={() => navigate(ROUTES.legal)}
              >
                Uslovi korišćenja
              </li>
              <li
                className="footer_list-item"
                onClick={() => navigate(ROUTES.legal)}
              >
                Politika privatnosti
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer className="footer">
      <div className="footer_header">
        <Logo />
        <Paragraph variant="p3" className="footer_description">
          Najveća platforma praktičnog znanja na Balkanu iz oblasti
          neurobiologije, psihologije i duhovnosti. Širok izbor edukacija,
          emisija i podkasta za proširenje percepcije i ostvarenje željenih
          transformacija.
        </Paragraph>
      </div>
      <ul className="footer_list">
        <li className="footer_list-header">Istraži</li>
        {istrazi.map(({ link, label }) => {
          return (
            <li className="footer_list-item" key={label}>
              <span onClick={() => navigate(link)}>{label}</span>
            </li>
          );
        })}
      </ul>
      <ul className="footer_list">
        <li className="footer_list-header">Sadržaj</li>
        {sortedContentFormats?.map((contentFormat: ContentFormatType) => {
          return (
            <li
              onClick={() =>
                navigate(
                  `${ROUTES.content}?contentFormatIds=${contentFormat.id}`
                )
              }
              key={contentFormat.name}
              className="footer_list-item"
            >
              {contentFormat.name}
            </li>
          );
        })}
      </ul>
      <ul className="footer_list">
        <li className="footer_list-header">Kompanija</li>
        {kompanija.map(({ link, label }) => {
          return (
            <li className="footer_list-item" key={label}>
              <span onClick={() => navigate(link)}>{label}</span>
            </li>
          );
        })}
      </ul>
      <h3 className="footer_follow">Zaprati nas na društvenim mrežama</h3>
      <div className="footer_social">
        <TikTokIcon
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open("https://www.tiktok.com/@neoummedia", "_blank")
          }
        />
        <InstagramIcon
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open("https://www.instagram.com/neoummedia/", "_blank")
          }
        />
        <YoutubeIcon
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open(
              "https://www.youtube.com/channel/UCMXp65nlDIXgJ92GcIXkfWg",
              "_blank"
            )
          }
        />
      </div>
      <Paragraph variant="p3" className="footer_copyright">
        Sva prava zadržana © 2023 NeoUm Media
      </Paragraph>
    </footer>
  );
};

export default Footer;

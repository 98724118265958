import SubscriptionPlan from "../components/subscriptionPlan/SubscriptionPlan";
import Carousel from "../components/carousel/Carousel";
import { useMediaQuery } from "react-responsive";
import { useSubscriptionDetails } from "../hooks/useSubscriptionDetails";
import Spinner from "../components/spinner/Spinner";
import { useSubscriptionPrice } from "../hooks/useSubscriptionPrice";
import useAuthPersistStore from "../store/authPersistStore";
import { REFERRAL_PARAM, REFERRAL_SEMINAR } from "../constants/url-params";
import { PlanTypes } from "../types";

const monthlyPlan = {
  title: "Mesečni",
  subtitle: "30 dana",
  subscriptionType: PlanTypes.Monthly,
  description:
    "Plan uključuje pristup svom sadržaju sa platforme u narednih 30 dana od trenutka kupovine. Mesečna pretplata se automatski obnavlja svakog meseca na dan kupovine pretplate.",
  items: [
    {
      checked: true,
      text: "48h besplatnog probnog perioda",
    },
    {
      checked: true,
      text: "Podkast sadržaj",
    },
    {
      checked: true,
      text: "Edukacije",
    },
    {
      checked: true,
      text: "Emisije",
    },
    {
      checked: true,
      text: "Neurobiologija",
    },
    {
      checked: true,
      text: "Meditacije",
    },
  ],
};

const yearlyPlan = {
  title: "Godišnji",
  subtitle: "12 meseci",
  subscriptionType: PlanTypes.Yearly,
  discount: 38,
  description:
    "Plan uključuje pristup svom sadržaju sa platforme u narednih 365 dana od trenutka kupovine. Godišnja pretplata se automatski obnavlja na svakih godinu dana na dan kupovine pretplate.",
  items: [
    {
      checked: true,
      text: "48h besplatnog probnog perioda",
    },
    {
      checked: true,
      text: "Podkast sadržaj",
    },
    {
      checked: true,
      text: "Edukacije",
    },
    {
      checked: true,
      text: "Emisije",
    },
    {
      checked: true,
      text: "Neurobiologija",
    },
    {
      checked: true,
      text: "Meditacije",
    },
  ],
};

const twoMonthsPlan = {
  title: "Seminar",
  subtitle: "2 meseca bonus NeoUm platforma - ",
  subscriptionType: PlanTypes.TwoMonths,
  discount: false, // TODO: proveri ovo cudo
  description:
    "Od datuma uplate za seminar dobijate na POKLON bonus pristup dva meseca NeoUm platformi. Sav sadržaj na platformi vam je dostupan. Nakon dva meseca, mesečna pretplata se automatski obnavlja (s tim da uplata ide mesec za mesec), osim ukoliko ne odlučite da otkažete pristup platformi.",
  items: [
    {
      checked: true,
      text: "Seminar",
    },
    {
      checked: true,
      text: "Podkast sadržaj",
    },
    {
      checked: true,
      text: "Edukacije",
    },
    {
      checked: true,
      text: "Emisije",
    },
    {
      checked: true,
      text: "Neurobiologija",
    },
    {
      checked: true,
      text: "Meditacije",
    },
  ],
};

const threeMonthsPlan = {
  title: "21 dan izazov & Radionica",
  subtitle: "3 meseca bonus NeoUm platforma - ",
  subscriptionType: PlanTypes.ThreeMonths,
  discount: 0, // TODO: proveri ovo cudo
  description:
    "Od datuma uplate za radionicu i izazov, dobijate na POKLON bonus pristup tri meseca NeoUm platformi. Sav sadržaj na platformi vam je dostupan. Nakon tri meseca, mesečna pretplata se automatski obnavlja (s tim da uplata ide mesec za mesec), osim ukoliko ne odlučite da otkažete pristup platformi.",
  items: [
    {
      checked: true,
      text: "21 dan izazov & Radionica",
    },
    {
      checked: true,
      text: "Podkast sadržaj",
    },
    {
      checked: true,
      text: "Edukacije",
    },
    {
      checked: true,
      text: "Emisije",
    },
    {
      checked: true,
      text: "Neurobiologija",
    },
    {
      checked: true,
      text: "Meditacije",
    },
  ],
};

// TODO: six months plan
const sixMonthsPlan =
{
  title: "Šestomesečni",
  subtitle: "6 meseci",
  subscriptionType: PlanTypes.SixMonths,
  discount: 19,
  description:
    "Plan uključuje pristup svom sadržaju sa platforme u narednih šest meseci od trenutka kupovine. Šestomesečna pretplata se automatski obnavlja svakih šest meseci na dan kupovine pretplate.",
  items: [
    // TODO: add seminar here
    {
      checked: true,
      text: "48h besplatnog probnog perioda",
    },
    {
      checked: true,
      text: "Podkast sadržaj",
    },
    {
      checked: true,
      text: "Edukacije",
    },
    {
      checked: true,
      text: "Emisije",
    },
    {
      checked: true,
      text: "Neurobiologija",
    },
    {
      checked: true,
      text: "Meditacije",
    },
  ],
};

const regularPlans = [
  monthlyPlan,
  sixMonthsPlan,
  yearlyPlan,
];

const refferalSrdjanTwoMonthsPlans = [twoMonthsPlan];
const refferalSrdjanThreeMonthsPlans = [threeMonthsPlan];

const Subscription = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const referralValue = searchParams.get(REFERRAL_PARAM);
  const isReferralForSeminar = referralValue === REFERRAL_SEMINAR;
  console.log(`referral "${REFERRAL_PARAM}": "${referralValue}"`);

  const isTablet = useMediaQuery({ query: "(min-width: 900px)" });

  const { isAuthenticated } = useAuthPersistStore();
  const { data: subscriptionDetails, isLoading } = useSubscriptionDetails({
    isAuthenticated,
  });

  // Load prices for each subscription plan

  const {
    data: monthlySubscriptionPrice,
    isLoading: isMonthlySubscriptionLoading,
  } = useSubscriptionPrice(regularPlans[0].subscriptionType);

  const {
    data: twoMonthsSubscriptionPrice,
    isLoading: isTwoMonthsSubscriptionLoading,
  } = useSubscriptionPrice(refferalSrdjanTwoMonthsPlans[0].subscriptionType);

  const {
    data: threeMonthsSubscriptionPrice,
    isLoading: isThreeMonthsSubscriptionLoading,
  } = useSubscriptionPrice(refferalSrdjanThreeMonthsPlans[0].subscriptionType);

  // // TODO: do six months too
  const {
    data: sixMonthsSubscriptionPrice,
    isLoading: isSixMonthsSubscriptionLoading,
  } = useSubscriptionPrice(regularPlans[1].subscriptionType);

  const {
    data: yearlySubscriptionPrice,
    isLoading: isYearlySubriptionLoading,
  } = useSubscriptionPrice(regularPlans[2].subscriptionType);

  // TODO: simplify this :D
  if (
    isAuthenticated &&
    (isLoading ||
      isMonthlySubscriptionLoading ||
      isYearlySubriptionLoading ||
      isSixMonthsSubscriptionLoading ||
      isTwoMonthsSubscriptionLoading ||
      isThreeMonthsSubscriptionLoading)
  ) {
    return <Spinner />;
  }

  // TODO: pretoci ovo gore u kod pa poresavaj casom

  // const prices = isReferralForSeminar ? refferalSrdjanPlans :

  const plans = isReferralForSeminar
    ? // ako je refferal od srdjana - uvek prikazuj samo 3 meseca
      [threeMonthsPlan]
    : // ulogovan i ima plan koji je 2 meseca
    isAuthenticated &&
      subscriptionDetails?.subscriptionType == PlanTypes.TwoMonths
    ? [monthlyPlan, twoMonthsPlan, yearlyPlan]
    : // ulogovan i ima plan koji je 3 meseca
    isAuthenticated &&
      subscriptionDetails?.subscriptionType == PlanTypes.ThreeMonths
    ? [monthlyPlan, threeMonthsPlan, yearlyPlan]
    : // a inace:
      // nije ulogovan - prikazi obicne planove
      // ulogovan i nema plana - prikazi obicne planove
      // ulogovan i ima plan koji nije 2 ili 3 meseca - prikazi obicne planove
      regularPlans;

  const pricesMap = {
    [PlanTypes.Monthly]: monthlySubscriptionPrice,
    [PlanTypes.TwoMonths]: twoMonthsSubscriptionPrice,
    [PlanTypes.ThreeMonths]: threeMonthsSubscriptionPrice,
    [PlanTypes.SixMonths]: sixMonthsSubscriptionPrice,
    // [PlanTypes.SixMonths]: undefined,
    [PlanTypes.Yearly]: yearlySubscriptionPrice,
  };

  const renderCarouselItem = (item: any) => {
    return (
      // @ts-ignore
      <SubscriptionPlan
        key={`${item.title} - ${item.subscriptionType}`}
        // @ts-ignore
        subscriptionDetails={subscriptionDetails}
        subscriptionPrice={pricesMap[item.subscriptionType as PlanTypes]}
        {...item}
      />
    );
  };

  return (
    <div className="subscription">
      <div className="subscription_header">
        <h1>
          {subscriptionDetails?.subscriptionType
            ? "Opcije pretplate"
            : "Odaberi plan"}
        </h1>
      </div>
      {/* TODO: sredi ovo sa tabletom. Moze da se uprosti kod sa jednom ili dve varijable spremljene iznad */}
      <div className={isTablet ? "subscription_grid" : "subscription_carousel"}>
        {isTablet ? (
          // if it's a tablet or smaller, render grid
          plans.map(renderCarouselItem)
        ) : (
          // if it's not, render carousel
          <Carousel
            items={plans}
            // @ts-ignore
            component={renderCarouselItem}
            options={{
              gap: 16,
              padding: 16,
              perMove: 1,
              perPage: 1,
            }}
          />
        )}
      </div>
    </div>
  );
};

Subscription.propTypes = {};

export default Subscription;

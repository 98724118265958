import React, { ReactNode } from "react";
import Paragraph from "../paragraph/Paragraph";

interface CardProps {
  icon?: ReactNode;
  label: string;
  onClick: () => void;
}

const Card: React.FC<CardProps> = ({ icon, label, onClick }) => {
  return (
    <div className="card" onClick={onClick}>
      {icon && icon}
      <Paragraph variant="p2">{label}</Paragraph>
    </div>
  );
};

export default Card;

import { useQuery } from "@tanstack/react-query";
import { SerialType } from "../types";

import { GET } from "../services/requests";

export const useFeaturedSerials = () => {
  const query = useQuery<SerialType[], Error, SerialType[]>({
    // @ts-ignore
    queryKey: ["FEATURED_SERIALS"],
    queryFn: async () => {
      const data = await GET(`/serials/featured-new`);
      return data;
    },
    staleTime: 5 * (60 * 1000), 
    cacheTime: 7 * (60 * 1000),
  });

  return { ...query };
};



import { useMutation } from "@tanstack/react-query";
import { DataOrParams, POST } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useExtendSubscription = () => {
  const mutation = useMutation({
    mutationFn: async (payload: DataOrParams) => {
        const data = await POST(`${ENDPOINTS.extendCancelledSubscription}`, payload);
        return data;
    }
});

  return { ...mutation };
};

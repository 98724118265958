import { useQuery } from "@tanstack/react-query";
import { SubscriptionPriceType } from "../types";

import { GET } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

// TODO: could we maybe create a hook that does multiple api calls?
// e.g. useSubscriptionPrices()
// it fetches all the prices - for monthly, 2 months, 6 months, 1 year plans
// and the component can just use what it needs.
// The way it's set up currently, 
// a component has to make separate calls for each price,
// even though it needs all of them fetched.

export const useSubscriptionPrice = (subscriptionType: string) => {
  const query = useQuery<SubscriptionPriceType, Error, SubscriptionPriceType>({
    // @ts-ignore
    queryKey: ["SUBSCRIPTION-PRICE", subscriptionType ],
    queryFn: async () => {
      const data = await GET(`${ENDPOINTS.subscriptionPriceDetails}/${subscriptionType}`);
      return data;
    },
    staleTime: 5 * (60 * 1000), 
    cacheTime: 7 * (60 * 1000),
  });

  return { ...query };
};

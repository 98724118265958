import { useNavigate } from "react-router-dom";
import { ReactComponent as ResetPasswordIlustration } from "../assets/images/success-ilustration.svg";
import Button from "../components/button/Button";
import Paragraph from "../components/paragraph/Paragraph";
import Steps from "../components/steps/Steps";
import useAuthPersistStore from "../store/authPersistStore";
import { ROUTES } from "../constants/routes";

const ResetPasswordSuccess = () => {
  const { user, isAuthenticated } = useAuthPersistStore();
  const navigate = useNavigate();

  return (
    <div className="success">
      <Steps steps={4} activeStep={4} />
      <div className="success_page">
        <ResetPasswordIlustration />
        <div className="success_content">
          <h1>Lozinka uspešno promenjena</h1>
          <div className="success_text">
            <Paragraph variant="p2">{user?.email}</Paragraph>
          </div>

          <div className="success_link">
            <Button
              variant="primary"
              label="Početna"
              onClick={() => {
                if (isAuthenticated) {
                  navigate(ROUTES.home);
                } else {
                  navigate(ROUTES.login);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;

import useAuthPersistStore from "../../store/authPersistStore";

function getInitials(fullName?: string) {
  // Split the full name into an array of words

  if (!fullName) {
    return null;
  }

  const words = fullName.split(" ");

  // Extract the first letter from each word
  const initials = words.map((word) => word.charAt(0)).join("");

  return initials.toUpperCase(); // Convert to uppercase for consistency
}
const UserInitials = () => {
  const { user } = useAuthPersistStore();

  return (
    <div className="user_initials">
      <span>{getInitials(user?.userProfile?.fullName)}</span>
    </div>
  );
};

export default UserInitials;

import Button from "../components/button/Button";
import Preview from "../components/preview/Preview";
import Carousel from "../components/carousel/Carousel";
import { ReactComponent as MicrophoneOutlined } from "../assets/icons/microphone-outlined.svg";
import { ReactComponent as PlayIcon } from "../assets/icons/play.svg";
import { ReactComponent as CommentIcon } from "../assets/icons/comment.svg";
import Paragraph from "../components/paragraph/Paragraph";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { EducatorType, EpisodeType } from "../types";
import Empty from "../components/empty/Empty";
import isEmpty from "lodash.isempty";
import Share from "../components/share/Share";
import VideoPlayer from "../components/player/VideoPlayer";
import useAuthPersistStore from "../store/authPersistStore";
import Comments from "../components/comments/Comments";
import { getImageRoute } from "../utils/getImageRoute";
import { ROUTES } from "../constants/routes";
import { useComments } from "../hooks/useComments";
import { useSerial } from "../hooks/useSerial";
import { useEpisode } from "../hooks/useEpisode";
import Spinner from "../components/spinner/Spinner";
import { useSubscriptionDetails } from "../hooks/useSubscriptionDetails";
import { useMyContent } from "../hooks/useMyContent";

const Episode = () => {
  const navigate = useNavigate();
  const { serialId, episodeId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hideLight, setHideLight] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 1550px)" });
  const { isAuthenticated } = useAuthPersistStore();

  const { data: subscriptionDetails } = useSubscriptionDetails({
    isAuthenticated,
  });
  const { data: myContent } = useMyContent({ isAuthenticated });

  const { state } = useLocation();
  const { data: serialData, isLoading: isSerialLoading } = useSerial(serialId);
  const { data: episodeData, isLoading: isEpisodeLoading } = useEpisode(
    serialId,
    episodeId
  );

  const togglePlayback = () => {
    setHideLight(true);
    setIsPlaying(!isPlaying);
  };

  const currentEpisode = state?.episode || episodeData;
  const currentSerial = state?.serial || serialData;

  const { data: comments, refetch } = useComments(currentSerial?.id);

  useEffect(() => {
    if (
      !currentEpisode &&
      !currentSerial &&
      !isSerialLoading &&
      !isEpisodeLoading
    ) {
      navigate(ROUTES.home);
    }
  }, [
    currentEpisode,
    currentSerial,
    isEpisodeLoading,
    isSerialLoading,
    navigate,
    state,
  ]);

  useEffect(() => {
    if (isSerialLoading && isEpisodeLoading) {
      return;
    }

    if (!serialData) {
      if (serialData?.stripePriceId) {
        if (
          !(
            myContent?.includes(currentSerial?.id) ||
            (subscriptionDetails?.subscriptionType &&
              !subscriptionDetails?.subscriptionAtRisk)
          )
        ) {
          navigate(`${ROUTES.serial}/${serialId}`);
        }
      }

      if (
        isEmpty(currentSerial?.episodes) ||
        !isAuthenticated ||
        !subscriptionDetails?.subscriptionType ||
        subscriptionDetails?.subscriptionAtRisk
      ) {
        navigate(`${ROUTES.serial}/${serialId}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialData]);

  if (isSerialLoading && isEpisodeLoading && !state) {
    return <Spinner />;
  }

  return (
    <>
      <div className="episode">
        <div>
          <div className="episode_cover">
            <VideoPlayer
              isPlaying={isPlaying}
              hideLight={hideLight}
              url={currentEpisode?.vimeoId}
              light={
                <img
                  alt={currentEpisode?.title}
                  src={
                    currentEpisode?.coverImage &&
                    getImageRoute(currentEpisode?.coverImage)
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              }
            />
          </div>
          <div>
            <div className="episode_serial">
              <MicrophoneOutlined />
              <Paragraph variant="title">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${ROUTES.serial}/${currentSerial?.id}`, {
                      state: { ...currentSerial },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {currentSerial?.title}
                </span>
                {" • "}
                {currentSerial?.educators.map(
                  (educator: EducatorType, index: number) => {
                    return (
                      <span
                        key={educator?.fullName}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          navigate(`${ROUTES.educator}/${educator?.id}`);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {educator?.fullName}
                        {index < currentSerial?.educators.length - 1
                          ? ", "
                          : ""}
                      </span>
                    );
                  }
                )}
              </Paragraph>
            </div>
            <h2 className="episode_title">{currentEpisode?.title}</h2>
          </div>
          <div className="episode_cta">
            <Button
              label="Gledaj epizodu"
              variant="primary"
              leftIcon={<PlayIcon />}
              onClick={togglePlayback}
              disabled={!isAuthenticated}
            />
            <Share />
            <Button
              label={`Komentari (${comments?.length || 0})`}
              variant="tertiary"
              leftIcon={<CommentIcon />}
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>
        {!isDesktop && (
          <div className="episode_rest">
            <Paragraph variant="p1">Ostale epizode iz serijala</Paragraph>
            {!isEmpty(currentSerial?.episodes) && (
              <Paragraph variant="p2">
                Broj epizoda: {currentSerial?.episodeCount}
              </Paragraph>
            )}
            <div className="episode_carousel">
              {isEmpty(currentSerial?.episodes) ? (
                <Empty emptySlide={{ type: "vertical" }} />
              ) : (
                <>
                  <Carousel
                    items={currentSerial?.episodes}
                    component={(item: EpisodeType, index: number) => (
                      <Preview
                        variant="vertical"
                        episode={item}
                        index={index}
                        isPlaying={item.id === currentEpisode?.id}
                        serial={currentSerial}
                      />
                    )}
                    options={{
                      gap: 6,
                      padding: 0,
                      perMove: 1,
                      fixedWidth: 280,
                      autoWidth: false,
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
        {isDesktop && (
          <div className="episode_rest">
            <Paragraph variant="p1">Ostale epizode iz serijala</Paragraph>
            {currentSerial?.episodeCount && (
              <Paragraph variant="p2">
                Broj epizoda: {currentSerial?.episodeCount}
              </Paragraph>
            )}
            <ul>
              {isEmpty(currentSerial?.episodes) ? (
                <Empty emptySlide={{ type: "horizontal" }} />
              ) : (
                <>
                  {currentSerial?.episodes?.map(
                    (item: EpisodeType, index: number) => (
                      <li key={item?.title}>
                        <Preview
                          episode={item}
                          index={index}
                          isTitleInDescription={true}
                          isPlaying={item.id === currentEpisode?.id}
                          serial={currentSerial}
                        />
                      </li>
                    )
                  )}
                </>
              )}
            </ul>
          </div>
        )}
      </div>
      {isOpen && (
        <Comments
          refetch={refetch}
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          comments={comments || []}
          id={currentSerial?.id}
        />
      )}
    </>
  );
};

export default Episode;

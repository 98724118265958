import Paragraph from "../paragraph/Paragraph";
import { ReactComponent as CartIcon } from "../../assets/icons/cart.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useMediaQuery } from "react-responsive";
import useAuthPersistStore from "../../store/authPersistStore";
import { useSubscriptionDetails } from "../../hooks/useSubscriptionDetails";

const PromotionDesktop = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthPersistStore();
  const isDesktop = useMediaQuery({ query: "(min-width: 1040px)" });
  const { data: subscriptionDetails } = useSubscriptionDetails({
    isAuthenticated,
  });
  const isActive = !!subscriptionDetails?.subscriptionType;
  const location = useLocation();

  const showOnRoutes = [
    ROUTES.home,
    ROUTES.educators,
    ROUTES.aboutUs,
    ROUTES.content,
  ];

  if (
    !isDesktop ||
    isActive ||
    !showOnRoutes.includes(location.pathname) ||
    !isAuthenticated
  ) {
    return null;
  }

  return (
    <div className="banner">
      <div
        onClick={() => navigate(ROUTES.subscription)}
        style={{ cursor: "pointer" }}
      >
        <CartIcon />
        <Paragraph variant="p3">
          Da biste mogli da gledate sadržaj, molimo Vas da odaberete plan.
        </Paragraph>
      </div>
    </div>
  );
};

PromotionDesktop.propTypes = {};

export default PromotionDesktop;

import { ReactComponent as EmptyIlustration } from "../../assets/images/empty-ilustration.svg";
import Paragraph from "../paragraph/Paragraph";

interface EmptyInterface {
  type: string;
  aspectRatio?: string;
  width?: string;
}

const Empty = ({ emptySlide }: { emptySlide: EmptyInterface }) => {
  // @ts-ignore
  if (emptySlide?.type === "horizontal") {
    return (
      <div
        style={{
          aspectRatio: emptySlide?.aspectRatio,
          width: emptySlide?.width,
        }}
        className="empty empty--horizontal"
      >
        <Paragraph variant="title">Nema rezultata</Paragraph>
        <EmptyIlustration />
      </div>
    );
  }

  return (
    <div
      style={{ aspectRatio: emptySlide?.aspectRatio, width: emptySlide?.width }}
      className="empty empty--vertical"
    >
      <EmptyIlustration />
      <Paragraph variant="title">Nema rezultata</Paragraph>
    </div>
  );
};

Empty.propTypes = {};

export default Empty;

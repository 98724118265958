import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import { trim } from "../../utils/trim";
import ClipLoader from "react-spinners/ClipLoader";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isLoading?: boolean;
  hasLoader?: boolean;
  label: string;
  variant:
    | "primary"
    | "secondary"
    | "tertiary"
    | "blue"
    | "error"
    | "white-outlined";
  maxContent?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
  const {
    leftIcon,
    rightIcon,
    isLoading,
    label,
    variant = "primary",
    hasLoader = false,
    maxContent,
    ...otherProps
  } = props;

  const buttonClassNames = trim(
    `button ${variant ? `button--${variant}` : ""} ${
      isLoading ? "button--loading" : ""
    } ${maxContent ? "button--max-content" : ""}`
  );

  return (
    <button className={buttonClassNames} {...otherProps}>
      {hasLoader && <span className="button_loader" />}
      <span className="button_inner">
        {leftIcon && <span className="button_icon">{leftIcon}</span>}
        <span className="button_label">{label}</span>
        {rightIcon && <span className="button_icon">{rightIcon}</span>}
      </span>
      {hasLoader && (
        <span className="button_loader">
          {isLoading && (
            <ClipLoader
              size={24}
              color="currentColor"
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </span>
      )}
    </button>
  );
};

export default Button;

import { useNavigate } from "react-router-dom";
import { ReactComponent as ResetEmailIlustration } from "../assets/images/success-ilustration.svg";
import Button from "../components/button/Button";
import Steps from "../components/steps/Steps";
import { ROUTES } from "../constants/routes";

const ResetEmailSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="success">
      <Steps steps={4} activeStep={4} />
      <div className="success_page">
        <ResetEmailIlustration />
        <div className="success_content">
          <h1>Email adresa uspešno promenjena</h1>
          <div className="success_link">
            <Button
              variant="primary"
              label="Početna"
              onClick={() => navigate(ROUTES.home)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetEmailSuccess;

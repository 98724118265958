import React from "react";
import { trim } from "../../utils/trim";

interface StepsProps {
  steps: number;
  activeStep: number;
}

const Steps: React.FC<StepsProps> = ({ steps, activeStep }) => {
  const stepArray = Array.from({ length: steps }, (_, index) => index + 1);

  return (
    <div className="steps">
      {stepArray.map((stepIndex) => (
        <div
          key={stepIndex}
          className={trim(
            `steps_step ${stepIndex <= activeStep ? "steps_step--active" : ""}`
          )}
        />
      ))}
    </div>
  );
};

export default Steps;

import Paragraph from "../components/paragraph/Paragraph";
import Button from "../components/button/Button";
import Link from "../components/Link/Link";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const NonAuthedBanner = () => {
  const navigate = useNavigate();

  return (
    <div className="auth-banner">
      <div className="auth-banner_banner">
        <h1>Tvoj put ka boljem životu kreće ovde.</h1>
        <Paragraph variant="p2">
          <span className="u-color-secondary-500">
            Najveća platforma praktičnog znanja na Balkanu
          </span>{" "}
          iz oblasti neurobiologije, psihologije i duhovnosti.
          Nauči mehanizme promene i uz protokole i prakse
          započni putovanje ka ličnom rastu i razvoju
          prijavom na našu platformu.
        </Paragraph>
        <div className="auth-banner_banner-cta">
          <Paragraph variant="p1">Napravi prvi korak</Paragraph>
          <Button
            style={{ width: "fit-content" }}
            variant="primary"
            label="Odaberi plan"
            onClick={() => navigate(ROUTES.subscription)}
          />
          <div className="auth-banner_no-account">
            <h3>Već imaš nalog?</h3>
            <Link
              label="Prijavi se"
              variant="primary"
              onClick={() => navigate(ROUTES.login)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonAuthedBanner;

import Paragraph from "../components/paragraph/Paragraph";
import { ReactComponent as ArrowLongRight } from "../assets/icons/arrow-long-right.svg";

import Link from "../components/Link/Link";
import Carousel from "../components/carousel/Carousel";
import { ContentFormatType, SerialType } from "../types";
import Badge from "../components/badge/Badge";
import { useNavigate } from "react-router-dom";
import { useFeaturedSerials } from "../hooks/userFeaturedSerials";
import { getImageRoute } from "../utils/getImageRoute";
import { useContentFormats } from "../hooks/useContentFormats";
import { useEffect, useState } from "react";
import { ROUTES } from "../constants/routes";
import { ReactComponent as ArrowShortLeft } from "../assets/icons/arrow-short-left.svg";
import { ReactComponent as ArrowShortRight } from "../assets/icons/arrow-short-right.svg";

const NewSerialSlide = ({ serial }: { serial: SerialType }) => {
  const navigate = useNavigate();
  const { data } = useContentFormats();
  const [currentContentFormat, setCurrentContentFormat] =
    useState<ContentFormatType | null>(null);

  useEffect(() => {
    if (!serial) {
      return;
    }

    setCurrentContentFormat(
      // @ts-ignore
      data?.find((item) => item.id === serial?.contentFormatId)
    );
  }, [data, serial]);

  return (
    <div className="new-serial_slide">
      <div className="new-serial_preview-wrap">
        <div className="new-serial_preview">
          <img
            src={getImageRoute(serial?.horizontalCoverImage)}
            alt={serial?.description}
          />
        </div>
        <div className="new-serial_preview-bar" />
      </div>
      <div className="new-serial_information">
        <div>
          <div className="new-serial_rating">
            {currentContentFormat && (
              <Badge
                variant="outlined"
                size="md"
                contentFormat={currentContentFormat}
                onClick={() =>
                  navigate(
                    `${ROUTES.content}?contentFormatIds=${currentContentFormat?.id}`
                  )
                }
              />
            )}
          </div>
          <h2 className="new-serial_title">{serial.title}</h2>
          <Paragraph variant="p2" className="new-serial_educator">
            {!!serial?.episodeCount && (
              <span>Broj epizoda: {serial?.episodeCount}</span>
            )}
          </Paragraph>
        </div>
        <Paragraph variant="p2">{serial.description}</Paragraph>
        <Link
          type="stretch"
          label={"Saznaj više o serijalu"}
          variant="primary"
          icon={<ArrowLongRight />}
          onClick={() =>
            navigate(`${ROUTES.serial}/${serial.id}`, {
              state: { ...serial },
            })
          }
        />
      </div>
    </div>
  );
};
const NewSerial = () => {
  const { data: featuredSerials } = useFeaturedSerials();
  const [splide, setSplide] = useState();

  if (!featuredSerials) {
    return <></>;
  }

  return (
    <div className="new-serial">
      <div className="new-serial__header">
        <h2>Novi serijali</h2>
        <div className="slider__arrows">
          {/* @ts-ignore */}
          <button onClick={() => splide?.go("<")}>
            <ArrowShortLeft />
          </button>
          {/* @ts-ignore */}
          <button onClick={() => splide?.go(">")}>
            <ArrowShortRight />
          </button>
        </div>
      </div>
      <div className="new-serial_carousel">
        <Carousel
          items={featuredSerials}
          component={(serial: SerialType) => {
            return <NewSerialSlide serial={serial} />;
          }}
          options={{
            autoWidth: false,
            pagination: true,
            gap: 16,
            perMove: 1,
            perPage: 1,
            arrows: false,
            autoplay: true,
            type: "loop",
          }}
          setSplide={setSplide}
        />
      </div>
    </div>
  );
};

export default NewSerial;

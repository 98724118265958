import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as WelcomeIlustration } from "../assets/images/welcome-ilustration.svg";
import Button from "../components/button/Button";
import Paragraph from "../components/paragraph/Paragraph";
import Steps from "../components/steps/Steps";
import { ROUTES } from "../constants/routes";
import useSubscriptionStore from "../store/subscriptionStore";
import { useCheckoutSubscription } from "../hooks/useCheckoutSubscription";

const CreateProfileSuccessfully = () => {
  const navigate = useNavigate();
  const { subscriptionType, setSubscriptionType } = useSubscriptionStore();
  const location = useLocation();
  const {
    mutate: checkoutSubscription,
    isLoading: isCheckoutSubscriptionLoading,
  } = useCheckoutSubscription();

  return (
    <div className="create-profile-success">
      <Steps steps={4} activeStep={4} />
      <div className="create-profile-success_page">
        <WelcomeIlustration />
        <div className="create-profile-success_content">
          <div className="create-profile-success_header">
            <h1>Vaš profil je uspešno kreiran.</h1>
            <Paragraph variant="p2">
              Da biste pristupili sadržaju na platformi, potrebno je da
              odaberete plan.
            </Paragraph>
          </div>
          <div className="create-profile-success_cta">
            {subscriptionType && (
              <Button
                isLoading={isCheckoutSubscriptionLoading}
                label={`Nastavi sa kupovinom ${
                  subscriptionType === "Monthly" ? "mesečnog" : "godišnjeg"
                } plana`}
                variant="primary"
                onClick={() => {
                  checkoutSubscription(
                    {
                      subscriptionType,
                      successUrl: `${process.env.REACT_APP_BASE_URL}${ROUTES.checkoutSuccess}?redirectTo=${location?.pathname}`,
                      cancelUrl: window.location.href,
                    },
                    {
                      onSuccess: (variable) => {
                        // @ts-ignore
                        const { checkoutLink } = variable;

                        if (checkoutLink) {
                          window.location.href = checkoutLink;
                        }
                      },
                    }
                  );

                  setSubscriptionType({ subscriptionType: null });
                }}
              />
            )}

            <Button
              label="Odaberi plan"
              variant="primary"
              onClick={() => {
                navigate(ROUTES.subscription);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProfileSuccessfully;

import { useQuery } from "@tanstack/react-query";
import { GET } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useComments = (id: string | undefined) => {
  const query = useQuery<any>(
    ["COMMENTS", id],
    async () => {
      const data = await GET(`${ENDPOINTS.comments}/${id}`);
      return data;
    },
    {
      enabled: !!id,
      staleTime: 0,
    }
  );

  return {...query};
};

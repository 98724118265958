import React, { ReactNode, FC, useEffect } from "react";
import useAuthPersistStore from "../store/authPersistStore";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuthPersistStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(ROUTES.login);
    }
  }, [isAuthenticated, navigate, user?.userProfile?.fullName]);

  return <>{children}</>;
};

export default PrivateRoute;

import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

interface AuthPersistStore {
  isAuthenticated: boolean,
  user: {
    email?: string,
    userProfile?: {
      city: string,
      dateOfBirth: string,
      fullName: string,
      country: string,
    }
  },
  accessToken: string,
  setLogout: () => void,
  setLogin: (accessToken: string, user: object) => void,
  setUser: (user: object) => void,
}

const useAuthPersistStore = create<AuthPersistStore>()(
  devtools(
    persist((set) => ({
      isAuthenticated: false,
      user: {},
      accessToken: '',
      setLogin: (accessToken: string, user: object) => set({ accessToken, isAuthenticated: true, user }),
      setUser: (user: object) => set({ user }),
      setLogout: () => set({ accessToken: '', isAuthenticated: false, user: {} }),
    }),
    {
      name: 'auth-persist-store',
      storage: createJSONStorage(() => localStorage),
    })
  )
)

export default useAuthPersistStore
import React, { Dispatch, SetStateAction } from "react";

import isEmpty from "lodash.isempty";
import { useNavigate } from "react-router-dom";
import Empty from "../components/empty/Empty";
import { SerialType } from "../types";
import { getImageRoute } from "../utils/getImageRoute";
import { ROUTES } from "../constants/routes";
import Badge from "../components/badge/Badge";
import Paragraph from "../components/paragraph/Paragraph";
import Pagination from "../components/pagination/Pagination";
import Spinner from "../components/spinner/Spinner";
import { useMediaQuery } from "react-responsive";

interface ContentCategoryProps {
  items: SerialType[] | undefined;
  totalCount?: number;
  pagination?: {
    perPage: number;
    pageNumber: number;
  };
  setPagination?: Dispatch<
    SetStateAction<{ perPage: number; pageNumber: number }>
  >;
  isLoading: boolean;
}

const ContentCategory: React.FC<ContentCategoryProps> = ({
  items,
  totalCount,
  pagination,
  setPagination,
  isLoading,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  return (
    <div className="content-category">
      {isEmpty(items) && (
        <ul className="content-category_grid">
          <Empty
            emptySlide={{
              type: "Vertical",
            }}
          />
        </ul>
      )}
      {!isEmpty(items) && (
        <>
          <ul className="content-category_grid">
            {items?.map((item: SerialType) => (
              <li
                onClick={() =>
                  navigate(`${ROUTES.serial}/${item.id}`, {
                    state: { ...item },
                  })
                }
                className="content-category_item"
                key={item?.id}
              >
                <img
                  src={getImageRoute(item?.verticalCoverImage)}
                  alt={item?.title}
                />
                <div className="content-category_item-category">
                  {item?.contentFormat && (
                    <Badge
                      variant="outlined"
                      size="sm"
                      contentFormat={item?.contentFormat}
                      noLabel={isMobile}
                    />
                  )}
                </div>

                <div className="content-category_item-episodes">
                  <Paragraph variant="p3">
                    Broj epizoda: {item?.episodeCount}
                  </Paragraph>
                </div>
              </li>
            ))}
            {isLoading && (
              <div className="content-category__loader">
                <Spinner />
              </div>
            )}
          </ul>
          {pagination && setPagination && totalCount && (
            <div className="content-category_pagination">
              <Pagination
                totalCount={totalCount}
                pagination={pagination}
                setPagination={setPagination}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContentCategory;

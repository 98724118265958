import React, { FC, ReactNode } from "react";
import { trim } from "../../utils/trim";

export interface LinkProps {
  label: string;
  icon?: ReactNode;
  variant: "primary" | "secondary" | "tertiary";
  type?: "stretch";
  onClick?: () => void;
}

const Link: FC<LinkProps> = ({
  label,
  icon,
  variant = "primary",
  type,
  onClick,
  ...otherProps
}) => {
  const linkClassName = trim(
    `link ${variant ? `link--${variant}` : ""} ${type ? `link--${type}` : ""}`
  );

  return (
    <a className={linkClassName} {...otherProps} onClick={onClick}>
      <span className="link_label">{label}</span>
      {icon && <span className="link_icon">{icon}</span>}
    </a>
  );
};

export default Link;

import React from "react";
import Paragraph from "../paragraph/Paragraph";
import { EpisodeType, SerialType } from "../../types";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import { ReactComponent as ConfirmIcon } from "../../assets/icons/confirm.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/locked.svg";
import { getImageRoute } from "../../utils/getImageRoute";
import { trim } from "../../utils/trim";
import { ROUTES } from "../../constants/routes";

interface PreviewInterface {
  episode: EpisodeType;
  variant?: "vertical" | "horizontal";
  index?: number;
  isTitleInDescription?: boolean;
  isPlaying?: boolean;
  serial?: SerialType;
  isDisabled?: boolean;
}

const Preview: React.FC<PreviewInterface> = ({
  variant = "horizontal",
  episode,
  index,
  isTitleInDescription,
  isPlaying,
  serial,
  isDisabled,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        if (isDisabled) {
          navigate(ROUTES.subscription);
          return;
        }

        navigate(`/serial/${serial?.id}/episode/${episode?.id}`, {
          state: { episode, serial },
        });
      }}
      className={trim(`preview ${variant ? `preview--${variant}` : ""}`)}
    >
      <div className="preview_image">
        <img
          src={getImageRoute(episode?.coverImage)}
          alt={`${episode?.title} - ${episode?.description}`}
        />
        {/* @ts-ignore */}
        {!isNaN(index) && index >= 0 && (
          <div className="preview__ep">
            {/* @ts-ignore */}
            <Paragraph variant="p2">{`Ep ${index + 1}`}</Paragraph>
          </div>
        )}
        <div
          className={`preview_play ${isPlaying ? "preview_play--playing" : ""}`}
        >
          {isDisabled ? (
            <LockIcon />
          ) : isPlaying ? (
            <ConfirmIcon />
          ) : (
            <PlayIcon />
          )}
        </div>
        {!isTitleInDescription && (
          <div className="preview_title">
            <Paragraph variant="title">
              {episode?.title ||
                "Ep 1: Neki malo duži naziv epizode Ep 1: Neki malo duži naziv epizode"}
            </Paragraph>
          </div>
        )}
      </div>
      <div className="preview_content">
        {isTitleInDescription && (
          <Paragraph variant="title">
            {episode?.title ||
              "Ep 1: Neki malo duži naziv epizode Ep 1: Neki malo duži naziv epizode"}
          </Paragraph>
        )}
      </div>
    </div>
  );
};

export default Preview;

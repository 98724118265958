import { useQuery } from "@tanstack/react-query";
import { GET } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useEducator = (id: string | undefined) => {
  const query = useQuery<any>(
    ["EDUCATOR", id],
    async () => {
      const data = await GET(`${ENDPOINTS.educator}/${id}`);
      return data;
    },
    {
      enabled: !!id,
      staleTime: 5 * (60 * 1000), 
      cacheTime: 7 * (60 * 1000),
    }
  );

  return {...query};
};

import Paragraph from "../components/paragraph/Paragraph";

const Legal = () => {
  return (
    <div className="legal">
      <div>
        <div>
          <div>
            <h1>Politika privatnosti</h1>
            <Paragraph variant="p1">
              Molimo vas da pažljivo pročitate ovu politiku kako biste bolje
              razumeli kako tretiramo vaše podatke.
            </Paragraph>
          </div>

          <div>
            <h3>Član 1</h3>
            <h2>Informacije o Kompaniji</h2>
            <Paragraph variant="p2">
              Platforma na internet stranici neoummedia.com je vlasništvo
              kompanije NeoUm Media d.o.o. Pula, ul. Osmi Ogranak br. 2, Oib:
              79168716130, kontakt e-mail: office@neoummedia.com
            </Paragraph>
          </div>

          <div>
            <h3>Član 2</h3>
            <h2>Prikupljanje i Upotreba Podataka</h2>
            <ol>
              <li>
                <Paragraph variant="p2">
                  Platforma na internet stranici neoummedia.com je vlasništvo
                  kompanije NeoUm Media d.o.o. Pula, ul. Osmi Ogranak br. 2,
                  Oib: 79168716130, kontakt e-mail: office@neoummedia.com
                </Paragraph>
              </li>
              <li>
                <Paragraph variant="p2">
                  Ovi podaci se neće deliti s trećim stranama.
                </Paragraph>
              </li>
              <li>
                <Paragraph variant="p2">
                  Koristimo podatke kako bismo vam pružili usluge,
                  personalizovali preporuke i bolje razumeli vaše potrebe.
                </Paragraph>
              </li>
            </ol>
          </div>

          <div>
            <h3>Član 3</h3>
            <h2>Sigurnost Podataka</h2>
            <Paragraph variant="p2">
              Vaši podaci su nam izuzetno važni, te primenjujemo sigurnosne mere
              kako bismo ih zaštitili od neovlašćenog pristupa ili zloupotrebe.
              Ove mere uključuju enkripciju podataka, tehničke i organizacione
              mere sigurnosti, kao i redovne sigurnosne revizije.
            </Paragraph>
          </div>

          <div>
            <h3>Član 4</h3>
            <h2>Pravna Osnova za Obradu Podataka</h2>
            <Paragraph variant="p2">
              Obrada vaših ličnih podataka zasniva se na vašem pristanku. Možda
              ćemo takođe obrađivati vaše podatke kako bismo ispunili ugovor s
              vama ili u skladu s važećim zakonima.
            </Paragraph>
          </div>

          <div>
            <h3>Član 5</h3>
            <h2>Čuvanje Podataka</h2>
            <Paragraph variant="p2">
              Podatke čuvamo onoliko dugo koliko je potrebno za pružanje usluga
              i u skladu sa važećim zakonima.
            </Paragraph>
          </div>

          <div>
            <h3>Član 6</h3>
            <h2>Prava Korisnika</h2>
            <Paragraph variant="p2">
              Imate pravo pristupa, ispravka i brisanja vaših ličnih podataka.
              Takođe možete povući svoj pristanak za obradu podataka u bilo kom
              trenutku. Za ostvarivanje ovih prava ili za dodatne informacije,
              molimo vas da nas kontaktirate.
            </Paragraph>
          </div>

          <div>
            <h3>Član 7</h3>
            <h2>Cene i Planovi</h2>
            <Paragraph variant="p2">
              Naša platforma nudi mesečne i godišnje planove koji omogućavaju
              pristup našem sadržaju. Cene se mogu pronaći na sledećoj stranici.
              neoummedia.com/subscription
            </Paragraph>
          </div>

          <div>
            <h3>Član 8</h3>
            <h2>Kolačići i Praćenje</h2>
            <Paragraph variant="p2">
              Naša platforma koristi kolačiće i slične tehnologije da poboljša
              vaše iskustvo. Kolačići su male tekstualne datoteke koje se čuvaju
              na vašem uređaju radi prikupljanja informacija o vašem ponašanju
              na platformi. Koristimo ih za personalizaciju iskustva, analizu
              saobraćaja i druge svrhe. Možete upravljati podešavanjima kolačića
              u svom pretraživaču.
              <br />
              <br />
              User Token - Accsess Token - Authenicated Token
            </Paragraph>
          </div>

          <div>
            <h3>Član 9</h3>
            <h2>Prenos Podataka Trećim Stranama</h2>
            <Paragraph variant="p2">
              Podaci se neće deliti s trećim stranama bez vašeg izričitog
              pristanka, osim u slučajevima gde je to zakonom ili ugovorom
              propisano. Takođe osiguravamo da treće strane koje dobiju pristup
              vašim podacima primenjuju odgovarajuće mere sigurnosti.
            </Paragraph>
          </div>

          <div>
            <h3>Član 10</h3>
            <h2>Ograničenja i Pravila Korišćenja</h2>
            <ol>
              <li>
                <Paragraph variant="p2">
                  Strogo zabranjujemo bilo kakvu monetizaciju ili neovlašćeno
                  kopiranje našeg sadržaja.
                </Paragraph>
              </li>
              <li>
                <Paragraph variant="p2">
                  Korisnici su obavezni poštovati pravila zajednice i
                  komunicirati s poštovanjem prema ostalim korisnicima.
                </Paragraph>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <h1>Uslovi Korišćenja</h1>
            <Paragraph variant="p2">
              Molimo vas da pažljivo pročitate ove uslove kako biste bolje
              razumeli pravne dokumente koji regulišu odnos između NeoUm Media
              doo. i korisnika tih usluga.
            </Paragraph>
          </div>
          <div>
            <h3>Član 1</h3>
            <h2>Opšti uslovi</h2>
            <Paragraph variant="p2">
              Pristupanjem i korišćenjem NeoUm Media platforme, prihvatate ove
              uslove korišćenja.
            </Paragraph>
          </div>
          <div>
            <h3>Član 2</h3>
            <h2>Pitanja o Intelektualnom Vlasništvu</h2>
            <Paragraph variant="p2">
              Sav sadržaj na našoj platformi, uključujući video materijale,
              tekst, slike i druge medijske sadržaje, podložan je autorskim
              pravima i drugim zakonima o intelektualnom vlasništvu. Korišćenje
              ili distribucija ovog sadržaja bez dozvole je strogo zabranjeno.
            </Paragraph>
          </div>
          <div>
            <h3>Član 3</h3>
            <h2>Komunikacija sa Ostalim Korisnicima</h2>
            <Paragraph variant="p2">
              Korisnici mogu koristiti interni sistem komentara za komunikaciju
              unutar platforme. Očekujemo da se korisnici ponašaju s poštovanjem
              prema drugima i da ne koriste ove kanale za neprimerenu
              komunikaciju, zlostavljanje ili širenje neovlašćenog sadržaja.
            </Paragraph>
          </div>
          <div>
            <h3>Član 4</h3>
            <h2>Zaštita Odgovornosti</h2>
            <Paragraph variant="p2">
              Korišćenje naše platforme je na vlastitu odgovornost. NeoUm Media
              ne snosi odgovornost za bilo kakvu štetu ili gubitke koji
              proizilaze iz korišćenja naše platforme, uključujući gubitak
              podataka, prekide u pristupu ili neovlašćeni pristup računima
              korisnika.
            </Paragraph>
          </div>
          <div>
            <h3>Član 5</h3>
            <h2>Rezervisana Prava</h2>
            <Paragraph variant="p2">
              NeoUm Media zadržava pravo izmeniti ove uslove korišćenja i
              obaveštavaće korisnike o svakoj značajnoj promeni. Takođe
              zadržavamo pravo privremeno ili trajno ograničiti ili obustaviti
              pristup korisnicima koji krše ova pravila korišćenja.
            </Paragraph>
          </div>
          <div>
            <h3>Član 6</h3>
            <h2>Obaveze Korisnika</h2>
            <Paragraph variant="p2">
              Korisnici su obavezni poštovati sva pravila korišćenja platforme,
              uključujući zabranu monetizacije ili neovlašćenog kopiranja
              sadržaja sa platforme. Takođe se očekuje da komuniciraju sa
              poštovanjem prema ostalim korisnicima. Uplatom mesečne članarine,
              korisnik se slaže da novac nije moguće refundirati iz bilo kog
              razloga. Korisnik je odgovoran za dužinu mesečne pretplate, kao i
              odjavljivanje sa platforme.
            </Paragraph>
          </div>
          <div>
            <h3>Član 7</h3>
            <h2>Odnos između korisnika i NeoUm d.o.o.</h2>
            <Paragraph variant="p2">
              Za ugovorni odnos na odnosi između korisnika i NeoUm d.o.o.
              primenjuje se isključivo hrvatsko materijalno i procesno pravo. U
              slučaju spora, nadležan je sud na području Istarske županije.
            </Paragraph>
          </div>
          <div>
            <h3>Član 8</h3>
            <h2>Sadržaj NeoUm platforme</h2>
            <Paragraph variant="p2">
              Naš sadržaj je isključivo informativnog karaktera koji ne
              zamenjuje rad sa stručnim licem. Mišljenja predavača ne reflektuju
              nužno mišljenje kompanije. NeoUm ne snosi odgovornost za način na
              koji koristite i primenjujete informacije iz našeg sadržaja.
              Ukoliko vam je potrebna pomoć, obratite se stručnom licu u svom
              okruženju.
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

Legal.propTypes = {};

export default Legal;

import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

interface CarouselProps<ItemType> {
  items: any[] | undefined;
  component: (item: ItemType, index: number) => React.ReactNode;
  options?: object;
  setSplide?: Dispatch<SetStateAction<undefined>>;
}

function Carousel<ItemType>({
  items,
  component,
  options,
  setSplide,
}: CarouselProps<ItemType>) {
  const ref = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setSplide && setSplide(ref.current.splide);
    }
  });

  return (
    <div className="carousel">
      <Splide
        options={{
          type: "slide",
          autoWidth: true,
          autoHeight: true,
          arrows: false,
          ...options,
        }}
        // @ts-ignore
        ref={ref}
      >
        {items?.map((item, index) => (
          <SplideSlide key={index}>{component(item, index)}</SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default Carousel;

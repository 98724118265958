import { useNavigate } from "react-router-dom";
import Paragraph from "../paragraph/Paragraph";
import { istrazi, kompanija } from "../../constants/menu";
import { ContentFormatType } from "../../types";
import { ROUTES } from "../../constants/routes";
import { useSortedContentFormats } from "../../hooks/useSortedContentFormats";

const LoggedInNavigation: React.FC<{ close: () => void }> = ({ close }) => {
  const navigate = useNavigate();

  const onPress = (page: string) => {
    navigate(page);
    close();
  };

  const { data: sortedContentFormats } = useSortedContentFormats();

  return (
    <>
      <h3>Istraži</h3>
      <ul>
        {istrazi?.map(({ link, label }) => {
          return (
            <li key={label}>
              <Paragraph onClick={() => onPress(link)} variant="title">
                {label}
              </Paragraph>
            </li>
          );
        })}
      </ul>
      <h3>Sadržaj</h3>
      <ul>
        {sortedContentFormats?.map((contentFormat: ContentFormatType) => {
          return (
            <li key={contentFormat.name}>
              <Paragraph
                onClick={() =>
                  onPress(
                    `${ROUTES.content}?contentFormatIds=${contentFormat.id}`
                  )
                }
                variant="title"
              >
                {contentFormat.name}
              </Paragraph>
            </li>
          );
        })}
      </ul>
      <h3>Kompanija</h3>
      <ul>
        {kompanija?.map(({ link, label }) => {
          return (
            <li key={label}>
              <Paragraph onClick={() => onPress(link)} variant="title">
                {label}
              </Paragraph>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default LoggedInNavigation;

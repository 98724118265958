import Paragraph from "../components/paragraph/Paragraph";
import { ReactComponent as WhyIlustration } from "../assets/images/why-ilustration.svg";
import { ReactComponent as WhatIlustration } from "../assets/images/what-ilustration.svg";
import { ReactComponent as HowIlustration } from "../assets/images/how-ilustration.svg";
import { useMediaQuery } from "react-responsive";

const AboutUs = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <div className="about-us">
      <div className="about-us_header">
        <h2>O nama</h2>
        {isMobile ? (
          <h2>
            NeoUm Media je najveća platforma praktičnog znanja, iz oblasti
            neurobiologije, psihologije i duhovnosti, a ujedno i zajednica
            vođena misijom da ohrabri evoluciju svesti na prostoru Balkana.
          </h2>
        ) : (
          <h1>
            NeoUm Media je najveća platforma praktičnog znanja, iz oblasti
            neurobiologije, psihologije i duhovnosti, a ujedno i zajednica
            vođena misijom da ohrabri evoluciju svesti na prostoru Balkana.
          </h1>
        )}
      </div>
      <div className="about-us_list">
        <div className="about-us_item">
          <WhatIlustration />
          <div>
            <Paragraph variant="p1">Šta je naš fokus?</Paragraph>
            <h2>Istraživanje limita ljudskog potencijala.</h2>
            <Paragraph variant="p2">
              Naša učenja vam pomažu da istražite limite ljudskog potencijala,
              da se konektujete sa svojim unutrašnjim bićem, kao i da se
              podsetite koliko je vaša biologija zapravo moćna jer sve što vam
              je potrebno za promenu se, zaista, nalazi unutar vas.
            </Paragraph>
          </div>
        </div>
        <div className="about-us_item">
          <WhyIlustration />
          <div>
            <Paragraph variant="p1">Zašto ovo radimo?</Paragraph>
            <h2>Kako bismo pomogli da prevaziđete specifičan izazov.</h2>
            <Paragraph variant="p2">
              U našim edukacijama, emisijama i podkastima istražujemo različite
              oblasti koje vam mogu pomoći da prevaziđete specifičan izazov koji
              imate, kao i da naučite kako manifestujete realnost koja definiše
              vaše biće.
            </Paragraph>
          </div>
        </div>
        <div className="about-us_item">
          <HowIlustration />
          <div>
            <Paragraph variant="p1">Kako to pravimo?</Paragraph>
            <h2>Sa vašom članarinom imamo mogućnost da rastemo.</h2>
            <Paragraph variant="p2">
              Sa vašom članarinom imamo mogućnost da proizvodimo, editujemo i
              delimo originalne serijale sa sjajnim edukatorima i misliocima
              našeg podneblja koji žele da Balkan bude najbolje mesto za život.
              <br />
              <br />
              Sami možemo samo donekle dogurati. Uz vašu pomoć, možemo čuda
              napraviti! Radujemo se zajedničkom rastu i razvoju!
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

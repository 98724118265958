import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ChevronLeft } from "../../assets/icons/arrow-short-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/arrow-short-right.svg";

interface PaginationProps {
  totalCount: number;
  pagination: {
    perPage: number;
    pageNumber: number;
  };
  setPagination: (pagination: { perPage: number; pageNumber: number }) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  pagination,
  setPagination,
}) => {
  const [pages, setPages] = useState(0);

  useEffect(() => {
    setPages(Math.ceil(totalCount / pagination?.perPage));
  }, [pagination?.perPage, pagination.pageNumber, totalCount]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= pages && newPage !== pagination.pageNumber) {
      setPagination({ ...pagination, pageNumber: newPage });
    }
  };

  const renderPageButtons = () => {
    const pageButtons: JSX.Element[] = [];
    const maxPagesToShow = 3;

    const startPage = Math.max(1, pagination.pageNumber - 1);
    const endPage = Math.min(pages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={i === pagination.pageNumber}
          className="pagination_button"
          type="button"
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  if (pages === 0) {
    return null;
  }

  return (
    <div className="pagination">
      <button
        type="button"
        onClick={() => handlePageChange(pagination.pageNumber - 1)}
        disabled={pagination.pageNumber === 1}
        className="pagination_prev"
      >
        <ChevronLeft />
      </button>

      {renderPageButtons()}

      <button
        onClick={() => handlePageChange(pagination.pageNumber + 1)}
        disabled={pagination.pageNumber === pages}
        className="pagination_next"
        type="button"
      >
        <ChevronRight />
      </button>
    </div>
  );
};

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  pagination: PropTypes.shape({
    perPage: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default Pagination;

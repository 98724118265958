import { useMutation } from "@tanstack/react-query";
import { DataOrParams, POST } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useCreateComment = () => {
  const mutation = useMutation({
    mutationFn: async (payload: DataOrParams) => {
        const {id, ...restPayload} = payload;
        const data = await POST(`${ENDPOINTS.comments}/${id}`, {...restPayload});
        return data;
    }
});

  return { ...mutation };
};

import { useMutation } from "@tanstack/react-query";
import { DataOrParams, POST } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useChangeEmail = () => {
  const mutation = useMutation({
    mutationFn: async (payload: DataOrParams) => {
        const {userId, token, ...restPayload} = payload;
        const data = await POST(`${ENDPOINTS.changeEmail}?token=${token}&userId=${userId}`, {...restPayload});
        return data;
    }
});

  return { ...mutation };
};

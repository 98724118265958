import React from "react";
import { TagType } from "../../types";
import Link from "../Link/Link";
import { ReactComponent as ArrowLongRight } from "../../assets/icons/arrow-long-right.svg";
import { getImageRoute } from "../../utils/getImageRoute";

const replaceWithSpan = (text: string) => {
  return text.replace(/\*\*(.*?)\*\*/g, (match, group) => {
    return `<span>${group}</span>`;
  });
};

interface ClickableTagType extends TagType {
  onClick?: () => void;
}

const Category: React.FC<ClickableTagType> = ({
  tagImage,
  name,
  title,
  description,
  onClick,
}) => {
  const formattedTitle = title && replaceWithSpan(title);
  const formattedDescription = description && replaceWithSpan(description);

  return (
    <div className="category">
      <div className="category_image">
        {tagImage && <img src={getImageRoute(tagImage)} alt={title} />}
      </div>
      <div>
        {name && <h2 className="category_title">{name}</h2>}
        <div className="category_content">
          {formattedTitle && (
            <p
              className="paragraph paragraph--p1"
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: formattedTitle }}
            ></p>
          )}
          {formattedDescription && (
            <p
              className="paragraph paragraph--p2"
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: formattedDescription }}
            ></p>
          )}
        </div>

        <div className="categories_cta">
          <Link
            label="Pronadji odgovarajući sadržaj"
            variant="primary"
            type="stretch"
            icon={<ArrowLongRight />}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Category;

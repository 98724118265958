import { useQuery } from "@tanstack/react-query";
import { TagType } from "../types";
import { GET } from "../services/requests";
import { longCache } from '../constants/cache';

export const useTags = () => {
  const query = useQuery<TagType[], Error, TagType[]>({
    // @ts-ignore
    queryKey: ["TAGS"],
    queryFn: async () => {
      const data = await GET("/tags");
      return data;
    },
    ...longCache,
  });

  return { ...query };
};

import { useQuery } from "@tanstack/react-query";
import { GET } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useEducators = () => {
  const query = useQuery<any>(
    ["EDUCATORS"],
    async () => {
      const data = await GET(ENDPOINTS.educators);
      return data;
    },
    {
      staleTime: 5 * (60 * 1000), 
      cacheTime: 7 * (60 * 1000),
    }
  );

  return {...query};
};

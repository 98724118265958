import Input from "../components/input/Input";
import * as Yup from "yup";
import Button from "../components/button/Button";
import { Formik, Form } from "formik";
import Link from "../components/Link/Link";
import Checkbox from "../components/checkbox/Checkbox";
import Paragraph from "../components/paragraph/Paragraph";
import { ReactComponent as LoginIlustration } from "../assets/images/login-ilustration.svg";
import { useMediaQuery } from "react-responsive";
import { useLogin } from "../hooks/useLogin";
import { ROUTES } from "../constants/routes";
import { useNavigate } from "react-router-dom";
import useAuthPersistStore from "../store/authPersistStore";
import useSubscriptionStore from "../store/subscriptionStore";
import { useMutateSubscriptionDetails } from "../hooks/useMutateSubscriptionDetails";

const Login = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 800px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();
  const { mutate, isLoading } = useLogin();
  const { setLogin } = useAuthPersistStore();
  const { setSubscriptionType, subscriptionType } = useSubscriptionStore();
  const {
    mutate: getSubscriptionDetails,
    isLoading: isGetSubscriptionDetailsLoading,
  } = useMutateSubscriptionDetails();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email adresa nije ispravna")
      .required("Obavezno polje"),
    password: Yup.string()
      .required("Obavezno polje")
      .min(8, "Mora imati najmanje 8 karaktera"),
  });

  return (
    <div className="login">
      <div>
        <div className="login_header">
          <div>
            <h1>Prijavi se</h1>
            <Paragraph variant="p2">
              Dobrodošli nazad! Radujemo se vašem povratku ka ličnom
              unapređenju!
            </Paragraph>
          </div>
          {isTablet && !isDesktop && <LoginIlustration />}
        </div>
        <Formik
          onSubmit={(payload, { setFieldError }) => {
            mutate(
              {
                email: payload?.email,
                password: payload?.password,
                role: "User",
              },
              {
                onSuccess: (data) => {
                  // @ts-ignore
                  const { accessToken, user } = data;
                  setLogin(accessToken, user);

                  if (!user?.userProfile) {
                    navigate(ROUTES.createProfile);
                    return;
                  }

                  if (subscriptionType) {
                    getSubscriptionDetails(
                      {},
                      {
                        onSuccess: (variables) => {
                          if (
                            // @ts-ignore
                            variables?.subscriptionType === "Monthly" ||
                            // @ts-ignore
                            variables?.subscriptionType === "Yearly"
                          ) {
                            navigate(ROUTES.home);
                          } else {
                            navigate(ROUTES.confirmPlan, {
                              state: { subscriptionType: subscriptionType },
                            });
                            setSubscriptionType({ subscriptionType: null });
                          }
                        },
                      }
                    );

                    return;
                  }

                  navigate(ROUTES.home);
                },
                onError: () => {
                  setFieldError(
                    "email",
                    "Pogrešan email ili lozinka. Molimo vas proverite unesene podatke."
                  );
                },
              }
            );
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          initialValues={{
            email: "",
            password: "",
            rememberMe: false,
            this: "",
          }}
        >
          <Form className="login_form">
            <Input
              name="email"
              label="Email"
              placeholder="Unesite email adresu"
              type="email"
              autoFocus
            />
            <Input
              name="password"
              label="Lozinka"
              type="password"
              placeholder="Unesite željenu lozinku"
            />
            <div className="login_remember-me">
              <Checkbox name="rememberMe" label="Zapamti me" />
              <Link
                variant="secondary"
                label="Zaboravili ste lozinku?"
                onClick={() => navigate(ROUTES.forgotPassword)}
              />
            </div>

            <div className="login_cta">
              <Button
                variant="primary"
                label="Prijavi se"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading || isGetSubscriptionDetailsLoading}
                hasLoader={true}
              />
              {/* <h3>Ili nastavite uz</h3>
              <div className="login_social">
                <Button
                  leftIcon={<GoogleIcon />}
                  label="Google"
                  variant="secondary"
                  type="button"
                />
              </div> */}
            </div>
          </Form>
        </Formik>
        <div className="login_link">
          <h3>Nemate nalog?</h3>
          <Link
            label="Registruj se"
            variant="primary"
            onClick={() => navigate(ROUTES.register)}
          />
        </div>
      </div>
      {isDesktop && <LoginIlustration />}
    </div>
  );
};

export default Login;

import { useState, useEffect } from 'react';

const useDeviceInfo = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isIosDevice, setIsIosDevice] = useState(false);
  const [browserName, setBrowserName] = useState('');

  useEffect(() => {
    // Check if the device is mobile
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);

    // Check if the device is running iOS
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
    setIsIosDevice(isIos);

    // Get the browser name
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Chrome')) {
      setBrowserName('Chrome');
    } else if (userAgent.includes('Edge')) {
      setBrowserName('Edge');
    } else if (userAgent.includes('Safari')) {
      setBrowserName('Safari');
    } else if (userAgent.includes('Mozilla')) { 
      setBrowserName('Mozilla');
    } else {
      setBrowserName('Unknown');
    }
  }, []);

  return { isMobile, isIosDevice, browserName };
};

export default useDeviceInfo;
import Input from "../components/input/Input";
import * as Yup from "yup";
import Button from "../components/button/Button";
import { Formik, Form } from "formik";
import Paragraph from "../components/paragraph/Paragraph";
import { ReactComponent as ProfileIlustration } from "../assets/images/profile-ilustration.svg";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useUpdateProfile } from "../hooks/useUpdateProfile";
import useAuthPersistStore from "../store/authPersistStore";
import { useCountries } from "../hooks/useCountries";
import {
  convertCountryToIso3Code,
  convertIso3CodeToCountry,
} from "../utils/convertCountryToIso3Code";
import {
  convertFromISO8601,
  convertToIso8601,
} from "../utils/convertToIso8601";
import { toast } from "react-toastify";
import { ROUTES } from "../constants/routes";
import Autocomplete from "../components/autocomplete/Autocomplete";

const ChangeProfile = () => {
  const { countries } = useCountries();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ query: "(min-width: 800px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });
  const { user, setUser } = useAuthPersistStore();

  const { mutate, isLoading } = useUpdateProfile();

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Obavezno polje"),
    dateOfBirth: Yup.string()
      .required("Obavezno polje")
      .matches(
        /^\d{2}.\d{2}.\d{4}$/,
        "Mora biti u formatu dd.mm.yyyy (npr. 01.01.2023)"
      ),
    // country: Yup.string().required("Obavezno polje"),
    // city: Yup.string().required("Obavezno polje"),
  });

  return (
    <div className="create-profile">
      <div className="create-profile_page">
        <div>
          <div className="create-profile_header">
            <div className="create-profile_header-content">
              <h1>Promeni lične podatke</h1>
              <Paragraph variant="p2">{user?.email}</Paragraph>
            </div>
            {isTablet && !isDesktop && <ProfileIlustration />}
          </div>
          <Formik
            onSubmit={(values, actions) => {
              // const countryList = Object.values(countries);
              const { dateOfBirth, ...rest } = values;
              // const { country, dateOfBirth, ...rest } = values;
              // const iso3Code = convertCountryToIso3Code(country);

              // if (
              //   !countryList.some(
              //     (item) => item.toLowerCase() === country.toLowerCase()
              //   )
              // ) {
              //   toast("Država nije ispravna. Molimo vas unesite je ispravno.");
              //   return;
              // }

              mutate(
                {
                  // country: iso3Code,
                  // @ts-ignore
                  dateOfBirth: convertToIso8601(dateOfBirth),
                  ...rest,
                },
                {
                  onSuccess: (data) => {
                    // @ts-ignore
                    setUser(data);
                    navigate(ROUTES.changeProfileSuccess);
                  },
                  onError: (response) => {
                    actions.setFieldError(
                      "fullName",
                      // @ts-ignore
                      response?.response?.data?.message ||
                        "Something went wrong"
                    );
                  },
                }
              );
            }}
            validationSchema={validationSchema}
            initialValues={{
              fullName: user?.userProfile?.fullName || "",
              dateOfBirth: user?.userProfile?.dateOfBirth
                ? convertFromISO8601(user?.userProfile?.dateOfBirth)
                : "",
              // country: user?.userProfile?.country
              //   ? convertIso3CodeToCountry(user?.userProfile?.country)
              //   : "",
              // city: user?.userProfile?.city || "",
            }}
            enableReinitialize
          >
            <Form className="create-profile_form">
              <Input
                name="fullName"
                label="Ime i prezime"
                placeholder="Unesite ime i prezime"
                type="text"
              />
              <Input
                name="dateOfBirth"
                label="Datum rođenja"
                placeholder="Unesite datum rođenja"
                type="text"
                mask={"dd.mm.yyyy"}
                replacement={{ d: /\d/, m: /\d/, y: /\d/ }}
              />
              {/* <div className="create-profile_form-place">
                <Autocomplete
                  name="country"
                  label="Država"
                  placeholder="Unesite Državu"
                  type="text"
                  items={Object.values(countries)}
                />
                <Input
                  name="city"
                  label="Mesto"
                  placeholder="Unesite mesto"
                  type="text"
                />
              </div> */}
              <div className="create-profile_cta">
                <Button
                  maxContent={true}
                  onClick={() => navigate(ROUTES.profile)}
                  label="Nazad"
                  variant="secondary"
                />
                <Button
                  variant="primary"
                  label="Sačuvaj"
                  type="submit"
                  hasLoader={true}
                  isLoading={isLoading}
                />
              </div>
            </Form>
          </Formik>
        </div>
        {isDesktop && <ProfileIlustration />}
      </div>
    </div>
  );
};

export default ChangeProfile;

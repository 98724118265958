import { FC, InputHTMLAttributes, useRef } from "react";
import { useField } from "formik";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as ArrowLongRight } from "../../assets/icons/arrow-long-right.svg";
import { trim } from "../../utils/trim";

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  showIcon?: boolean;
}

const Search: FC<SearchProps> = ({ id, name, type, showIcon, ...props }) => {
  const [field] = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={trim(
        `search ${field.value || showIcon ? "search--searchable" : ""}`
      )}
    >
      <div className="search_icon">
        <SearchIcon />
      </div>
      <input
        id={name}
        className="search_field"
        ref={inputRef}
        {...field}
        {...props}
        value={field.value}
      />
      <button className="search_button" type="submit">
        <ArrowLongRight />
      </button>
    </div>
  );
};

export default Search;

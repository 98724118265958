import Paragraph from "../paragraph/Paragraph";
import { Formik, Form } from "formik";
import Search from "../search/Search";
import Card from "../card/Card";
import { ContentFormatType } from "../../types";
import { useNavigate } from "react-router-dom";
import { getImageRoute } from "../../utils/getImageRoute";
import { ROUTES } from "../../constants/routes";
interface FormatsInterface {
  categories: ContentFormatType[] | undefined;
}

const Formats = ({ categories }: FormatsInterface) => {
  const navigate = useNavigate();
  const items = categories?.filter(
    (item: ContentFormatType) => item.name !== "Meditacija"
  );

  return (
    <div className="formats">
      <h1 className="formats-title">Formati sadržaja prilagođeni svima</h1>
      <Paragraph variant="p1" className="formats-description">
        Novi ekskluzivan video sadržaj <span>svakog meseca</span>.
      </Paragraph>
      <Formik
        initialValues={{ search: "" }}
        onSubmit={(values) => {
          navigate(`${ROUTES.content}?search=${values?.search}`);
        }}
      >
        <Form className="formats-search">
          <Search name="search" placeholder="Pronađi željeni sadržaj" />
        </Form>
      </Formik>
      <div className="formats-card-grid">
        {items?.map((contentFormat: ContentFormatType) => (
          <Card
            key={contentFormat.name}
            label={contentFormat.name}
            onClick={() =>
              navigate(`${ROUTES.content}?contentFormatIds=${contentFormat.id}`)
            }
            icon={
              <img
                src={getImageRoute(contentFormat.icon)}
                alt={contentFormat.name}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Formats;

import User from "../components/user/User";
import Button from "../components/button/Button";
import Paragraph from "../components/paragraph/Paragraph";
import { ReactComponent as ProfileIlustration } from "../assets/images/profile-ilustration.svg";
import { useMediaQuery } from "react-responsive";
import Link from "../components/Link/Link";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FolderIcon } from "../assets/icons/folder-grid.svg";
import { ReactComponent as PasswordIcon } from "../assets/icons/password.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/email.svg";
import useAuthPersistStore from "../store/authPersistStore";
import { ROUTES } from "../constants/routes";
import { convertFromISO8601 } from "../utils/convertToIso8601";
import { convertIso3CodeToCountry } from "../utils/convertCountryToIso3Code";
import { useSubscriptionDetails } from "../hooks/useSubscriptionDetails";
import Spinner from "../components/spinner/Spinner";
import { useMyContent } from "../hooks/useMyContent";
import useLogout from "../hooks/useLogout";

const MyProfile = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 650px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1450px)" });
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuthPersistStore();
  const { data: subscriptionDetails, isLoading } = useSubscriptionDetails({
    isAuthenticated,
  });
  const { handleLogout } = useLogout();
  const { data: myContent, isLoading: isMyContentLoading } = useMyContent({
    isAuthenticated,
  });

  if (isLoading || isMyContentLoading) {
    return <Spinner />;
  }

  return (
    <div className="my-profile">
      <div className="my-profile_page">
        <div className="my-profile_header">
          <div className="my-profile_header-content">
            <h1>Moj profil</h1>
            <User hideSettings={true} />
          </div>
          {isTablet && !isDesktop && <ProfileIlustration />}
        </div>
        <div className="my-profile_profile-cta">
          <Button
            onClick={() => navigate(ROUTES.subscription)}
            variant="primary"
            label={
              subscriptionDetails?.subscriptionType
                ? "Opcije pretplate"
                : "Odaberi plan"
            }
          />
          <Button
            leftIcon={<FolderIcon />}
            variant="tertiary"
            label={`Moj Sadržaj (${myContent?.length})`}
            onClick={() => navigate(ROUTES.myContent)}
          />
        </div>
        <div className="my-profile_content">
          <div className="my-profile_information">
            <Paragraph variant="p3">Email</Paragraph>
            <Paragraph variant="subtitle">{user?.email}</Paragraph>
          </div>
          <div className="my-profile_information">
            <Paragraph variant="p3">Datum rođenja</Paragraph>
            <Paragraph variant="subtitle">
              {user?.userProfile?.dateOfBirth &&
                convertFromISO8601(user?.userProfile?.dateOfBirth)}
            </Paragraph>
          </div>
          {/* <div className="my-profile_information">
            <Paragraph variant="p3">Država</Paragraph>
            <Paragraph variant="subtitle">
              {user?.userProfile?.country &&
                convertIso3CodeToCountry(user?.userProfile?.country)}
            </Paragraph>
          </div>
          <div className="my-profile_information">
            <Paragraph variant="p3">Mesto</Paragraph>
            <Paragraph variant="subtitle">{user?.userProfile?.city}</Paragraph>
          </div> */}
        </div>
        <div className="my-profile_cta">
          <Button
            onClick={() => navigate(ROUTES.changeProfile)}
            variant="secondary"
            label="Promeni lične podatke"
          />
          <Button
            onClick={() => navigate(ROUTES.changePasswordRequest)}
            variant="tertiary"
            label="Promeni lozinku"
            leftIcon={<PasswordIcon />}
          />
          <Button
            onClick={() => navigate(ROUTES.changeEmail)}
            variant="tertiary"
            label={"Promeni email"}
            leftIcon={<EmailIcon />}
          />
        </div>
        <div className="my-profile_link">
          <h3>Želite da se odjavite?</h3>
          <Link onClick={handleLogout} label="Odjavi se" variant="tertiary" />
        </div>
      </div>
      {isDesktop && <ProfileIlustration />}
    </div>
  );
};

export default MyProfile;

import React, { useState, useEffect } from "react";
import Paragraph from "../paragraph/Paragraph";
import { ReactComponent as CloseIcon } from "../../assets/icons/cancel.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import Button from "../button/Button";
import SafariStepOne from "../../assets/images/safari-step-1.png";
import SafariStepTwo from "../../assets/images/safari-step-2.png";
import ChromeStepOne from "../../assets/images/chrome-step-1.png";
import ChromeStepTwo from "../../assets/images/chrome-step-2.png";
import EdgeStepOne from "../../assets/images/edge-step-1.png";
import EdgeStepTwo from "../../assets/images/edge-step-2.png";
import EdgeStepThree from "../../assets/images/edge-step-3.png";
import useDeviceInfo from "../../hooks/useDeviceInfo";
import { useMediaQuery } from "react-responsive";

const IMAGES = {
  Safari: [SafariStepOne, SafariStepTwo],
  Chrome: [ChromeStepOne, ChromeStepTwo],
  Edge: [EdgeStepOne, EdgeStepTwo, EdgeStepThree],
};

const Banner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile, browserName, isIosDevice } = useDeviceInfo();
  const isDesktop = useMediaQuery({ query: "(min-width: 1040px)" });
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showBanner, setShowBanner] = useState(
    sessionStorage.getItem("showBanner")
  );

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: any) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later
      setDeferredPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleDownload = () => {
    if (deferredPrompt) {
      // @ts-ignore
      deferredPrompt?.prompt();
      // @ts-ignore
      deferredPrompt?.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }

        setDeferredPrompt(null);
      });
    } else {
      setIsModalOpen(true);
    }
  };

  if (!isMobile || isDesktop || showBanner === "no") {
    return null;
  }

  const renderContent = () => {
    if (
      isIosDevice &&
      browserName !== "Safari" &&
      browserName !== "Chrome" &&
      browserName !== "Edge"
    ) {
      return (
        <>
          <Paragraph variant="p2">
            Da bi ste mogli instalirati aplikaciju molimo vas koristite Safari
            Browser
          </Paragraph>
          {IMAGES["Safari"]?.map((image, index) => (
            <img key={index} src={image} alt={`Step ${index + 1}`} />
          ))}
        </>
      );
    }

    if (
      isMobile &&
      !isIosDevice &&
      (browserName === "Chrome" ||
        browserName === "Edge" ||
        browserName === "Safari")
    ) {
      return (
        <>
          {/* @ts-ignore */}
          {IMAGES[browserName]?.map((image, index) => (
            <img key={index} src={image} alt={`Step ${index + 1}`} />
          ))}
        </>
      );
    }

    return (
      <>
        <Paragraph variant="p2">
          Da bi ste mogli instalirati aplikaciju molimo vas koristite Chrome
          Browser
        </Paragraph>
        {IMAGES["Chrome"]?.map((image, index) => (
          <img key={index} src={image} alt={`Step ${index + 1}`} />
        ))}
      </>
    );
  };

  return (
    <>
      <div className="banner">
        <div onClick={handleDownload} style={{ cursor: "pointer" }}>
          <DownloadIcon />
          <Paragraph variant="p3">
            <strong>Preuzmi aplikaciju</strong> i uživaj u svim prednostima.
          </Paragraph>
          <button
            className="banner__cta"
            onClick={(event) => {
              event?.stopPropagation();
              event?.preventDefault();
              setShowBanner("no");
              sessionStorage.setItem("showBanner", "no");
            }}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      {isModalOpen && (
        <div className="banner__wrap">
          <div className="banner__modal">
            <div className="banner__modal-header">
              <h2>Preuzmi aplikaciju</h2>
              <button onClick={() => setIsModalOpen(false)}>
                <CloseIcon />
              </button>
            </div>
            <div className="banner__modal-content">
              <div className="banner__modal-body">{renderContent()}</div>
              <div className="banner__modal-footer">
                <Button
                  variant="white-outlined"
                  label="Nazad"
                  onClick={() => setIsModalOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;

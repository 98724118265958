import Input from "../components/input/Input";
import * as Yup from "yup";
import Button from "../components/button/Button";
import { Formik, Form } from "formik";
import Link from "../components/Link/Link";
import Paragraph from "../components/paragraph/Paragraph";
import Steps from "../components/steps/Steps";
import { ReactComponent as LoginIlustration } from "../assets/images/login-ilustration.svg";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { useRequestPasswordReset } from "../hooks/useRequestPasswordReset";

const ForgotPassword = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 800px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();
  const { mutate, isLoading } = useRequestPasswordReset();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email adresa nije ispravna")
      .required("Obavezno polje"),
  });

  return (
    <div className="forgot-password">
      <Steps activeStep={1} steps={4} />
      <div className="forgot-password_page">
        <div className="forgot-password_content">
          <div className="forgot-password_header">
            <div className="forgot-password_header-content">
              <h1>Zaboravili ste lozinku?</h1>
              <Paragraph variant="p2">
                Dobrodošli nazad! Radujemo se vašem povratku ka ličnom
                unapređenju!
              </Paragraph>
            </div>
            {isTablet && !isDesktop && <LoginIlustration />}
          </div>
          <Formik
            onSubmit={(values, actions) => {
              mutate(
                { email: values?.email },
                {
                  onSuccess: () => {
                    navigate(ROUTES.forgotPasswordSuccess, {
                      state: {
                        email: values?.email,
                      },
                    });
                  },
                  onError: (response) => {
                    actions.setFieldError(
                      "email",
                      // @ts-ignore
                      response?.response?.data?.message ||
                        "Something went wrong"
                    );
                  },
                }
              );
            }}
            validationSchema={validationSchema}
            initialValues={{
              email: "",
            }}
          >
            <Form className="forgot-password_form">
              <Input
                name="email"
                label="Email"
                placeholder="Unesite email adresu"
                type="email"
                autoFocus
              />

              <Button
                variant="primary"
                label="Dalje"
                type="submit"
                hasLoader={true}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Form>
          </Formik>
          <div className="forgot-password_link">
            <h3>Nemate nalog?</h3>
            <Link
              label="Registruj se"
              variant="primary"
              onClick={() => navigate(ROUTES.register)}
            />
          </div>
        </div>
        {isDesktop && <LoginIlustration />}
      </div>
    </div>
  );
};

export default ForgotPassword;

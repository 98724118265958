import { useMutation } from "@tanstack/react-query";
import { DataOrParams, POST } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

// TODO: use T generic from makeRequest<T>() in other places too
//
export type RegisterPayload = {
  // TODO
};

export const useRegister = () => {
  const mutation = useMutation({
    mutationFn: async (payload: DataOrParams) => {
      const data = await POST<RegisterPayload>(ENDPOINTS.register, payload);
      return data;
    }
});

  return { ...mutation };
};

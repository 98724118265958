import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as EmailVerificationIlustration } from "../assets/images/email-verification-ilustration.svg";
import Link from "../components/Link/Link";
import Paragraph from "../components/paragraph/Paragraph";
import Steps from "../components/steps/Steps";
import { ROUTES } from "../constants/routes";

const ForgotPasswordSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <div className="success">
      <Steps steps={4} activeStep={2} />
      <div className="success_page">
        <EmailVerificationIlustration />
        <div className="success_content">
          <h1>Zatražena promena lozinke</h1>
          <div className="success_text">
            <Paragraph variant="p2">
              Promenite vašu lozinku, tako što ćete ispratiti korake koje smo
              vam poslali na
            </Paragraph>
            {state?.email && <Paragraph variant="p2">{state?.email}</Paragraph>}
          </div>
          <div className="success_link">
            <h3>Već ste potvrdili email?</h3>
            <Link
              label="Prijavi se"
              variant="primary"
              onClick={() => navigate(ROUTES.login)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;

export const ENDPOINTS = {
    login: '/auth/login',
    register: '/auth/register',
    completeRegistration: '/auth/complete-registration',
    changePasswordRequest: '/auth/request-password-change',
    changePassword: '/auth/change-password',
    resetPassword: '/auth/reset-password',
    changeEmailRequest: '/auth/request-email-change',
    changeEmail: '/auth/change-email',
    updateProfile: '/users/update-profile',
    comments: '/comments/serials',
    educators: '/educators',
    educator: '/educators',
    educatorContent: 'serials/latest-content-for-educator',
    serialsSearch: 'serials/search',
    serial: '/serials',
    serialsByIds: "/serials/serials-by-id",
    resetPasswordRequest: "auth/request-password-reset",
    subscriptionCheckout: "/payment/checkout-for-subscription",
    checkoutForSerial: "/payment/checkout-for-sold-standalone-serial",
    changeSubscription: "/payment/customer-portal-flow",
    subscriptionDetails: "/payment/subscription-details",
    myContent: "/payment/my-content-serial-ids",
    stripePriceDetails: "/payment/stripe-price-details",
    subscriptionPriceDetails: "/payment/subscription-price-details",
    extendCancelledSubscription: "/payment/extend-cancelled-subscription"
}
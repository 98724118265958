import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { trim } from "../../utils/trim";

interface HeaderDropdownMenuProps {
  label: string;
  items:
    | {
        link: string;
        label: string;
        imageUrl?: string | undefined;
      }[]
    | undefined; // Define the type of items as an array of strings
}

const HeaderDropdownMenu: React.FC<HeaderDropdownMenuProps> = ({
  label,
  items,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLLIElement | null>(null); // Specify the type of the ref
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <li
      className={trim(`header_link ${isOpen ? "header_link--active" : ""}`)}
      ref={ref}
      onClick={() => setIsOpen(!isOpen)}
    >
      <p>{label}</p>

      {isOpen && (
        <ul className="header_dropdown">
          {items?.map(({ link, label, imageUrl }, index) => (
            <li onClick={() => navigate(link)} key={index}>
              <span>
                {imageUrl && <img src={imageUrl} alt={label} />}
                <span>{label}</span>
              </span>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default HeaderDropdownMenu;

/* eslint-disable react-hooks/rules-of-hooks */
import { FC, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useField } from "formik";
import { ReactComponent as EyeHide } from "../../assets/icons/eye-hide.svg";
import { ReactComponent as EyeShow } from "../../assets/icons/eye-show.svg";
import { Replacement, useMask } from "@react-input/mask";
import { trim } from "../../utils/trim";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  mask?: string;
  replacement?: string | Replacement | undefined;
}

const Input: FC<InputProps> = ({
  label,
  id,
  name,
  type,
  mask,
  replacement,
  ...props
}) => {
  const [field, meta] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [hideValue, setHideValue] = useState(type === "password");
  const inputRef =
    mask && replacement
      ? useMask({
          mask: mask,
          replacement: replacement,
        })
      : useRef<HTMLInputElement>(null);

  const [isLabelFloating, setIsLabelFloating] = useState(
    !!field.value || isFocused
  );

  useEffect(() => {
    setIsLabelFloating(!!field.value || isFocused);
  }, [field.value, isFocused]);

  return (
    <div
      className={trim(
        `input ${isLabelFloating ? "input--floating" : ""} ${
          isFocused ? "input--focused" : ""
        } ${meta.error ? "input--error" : ""}`
      )}
    >
      <div className="input_inner">
        <label
          className={trim(
            `input_label ${isLabelFloating ? "input_label--floating" : ""}`
          )}
          htmlFor={name}
        >
          {label}
        </label>
        <div className="input_wrap">
          <input
            id={name}
            className="input_field"
            ref={inputRef}
            {...field}
            {...props}
            onFocus={() => setIsFocused(true)}
            onBlur={(event) => {
              setIsFocused(false);
              field.onBlur(event);
            }}
            placeholder={isLabelFloating ? props?.placeholder : ""}
            type={type !== "password" ? type : hideValue ? "password" : "text"}
          />
          {type === "password" && (
            <button
              type="button"
              onClick={() => {
                setHideValue(!hideValue);
                inputRef.current?.focus();
              }}
              className="input-hide-show"
            >
              {hideValue ? <EyeHide /> : <EyeShow />}
            </button>
          )}
        </div>
      </div>
      <span className="input_error">{meta.error || "error"}</span>
    </div>
  );
};

export default Input;

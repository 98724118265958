const PageNotFound = () => {
  return (
    <div className="not-found">
      <h2>404</h2>
      <h3>Page Not Found</h3>
    </div>
  );
};

PageNotFound.propTypes = {};

export default PageNotFound;

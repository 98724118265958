import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as PaymentIlustration } from "../assets/images/Payment.svg";
import Button from "../components/button/Button";
import Paragraph from "../components/paragraph/Paragraph";
import { ROUTES } from "../constants/routes";
import { useCheckoutSubscription } from "../hooks/useCheckoutSubscription";
import { useEffect } from "react";
import { PlanTypes } from "../types";

const ConfirmPlan = () => {
  const { state } = useLocation();
  const {
    mutate: checkoutSubscription,
    isLoading: isCheckoutSubscriptionLoading,
  } = useCheckoutSubscription();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.subscriptionType) {
      navigate(ROUTES.subscription);
    }
  }, [navigate, state]);

  const handleCheckout = () => {
    checkoutSubscription(
      {
        subscriptionType: state?.subscriptionType,
        successUrl: `${process.env.REACT_APP_BASE_URL}${ROUTES.checkoutSuccess}?redirectTo=${ROUTES.home}`,
        cancelUrl: window.location.href,
      },
      {
        onSuccess: (variable) => {
          // @ts-ignore
          const { checkoutLink } = variable;

          if (checkoutLink) {
            window.location.href = checkoutLink;
          }
        },
      }
    );
  };

    //planovi koji imaju free trial
    const specialSubscriptionTypes = [
      PlanTypes.Monthly,
      PlanTypes.SixMonths,
      PlanTypes.Yearly
    ];

    const hasFreeTrial = specialSubscriptionTypes.includes(state?.subscriptionType as PlanTypes);
    //u zavisnosti od plana, label ima drugi tekst
    //za ovaj slucaj imamo nemamo free trial za 3meseca i 2meseca, pa nam treba drugaciji tekst
    const buttonLabel = hasFreeTrial
      ? "Započni 48h besplatnog perioda"
      : "Uplata za radionicu & izazov";

    const headerText = hasFreeTrial 
      ? "Potvrda plana"
      : "Potvrda uplate";

  return (
    <div className="confirm-plan">
      <PaymentIlustration className="confirm-plan__image" />
      <div className="confirm-plan__content">
        <h1 className="confirm-plan__title">{headerText}</h1>
        {state?.subscriptionType === "Monthly" && (
          <div className="confirm-plan__card">
            <div>
              <h2>Mesečni</h2>
              <Paragraph variant="p1">30 dana</Paragraph>
            </div>
            <div>
              <Paragraph variant="p2">
                Plan uključuje pristup svom sadržaju sa platforme u narednih 30
                dana od trenutka kupovine.
              </Paragraph>
            </div>
            <h2>19€</h2>
          </div>
        )}
        {state?.subscriptionType === PlanTypes.TwoMonths && (
          <div className="confirm-plan__card">
            <div>
              <div className="confirm-plan__card-title">
                <h2>Pristup platformi 2 meseca</h2>
                {/* <div className="confirm-plan__discount">
                  <Paragraph variant="title">-20%</Paragraph>
                </div> */}
              </div>
              <Paragraph variant="p1">2 meseca</Paragraph>
            </div>
            <div>
              <Paragraph variant="p2">
                Plan uključuje pristup svom sadržaju sa platforme u naredna dva meseca od trenutka kupovine.
              </Paragraph>
            </div>
            <h2>38€</h2>
          </div>
        )}
        {state?.subscriptionType === PlanTypes.ThreeMonths && (
          <div className="confirm-plan__card">
            <div>
              <div className="confirm-plan__card-title">
                <h2>Pristup platformi 3 meseca</h2>
                {/* <div className="confirm-plan__discount">
                  <Paragraph variant="title">-20%</Paragraph>
                </div> */}
              </div>
              <Paragraph variant="p1">3 meseca</Paragraph>
            </div>
            <div>
              <Paragraph variant="p2">
                Plan uključuje pristup svom sadržaju sa platforme u naredna tri meseca od trenutka kupovine.
              </Paragraph>
            </div>
            <h2>57€</h2>
          </div>
        )}
        {state?.subscriptionType === PlanTypes.Yearly && (
          <div className="confirm-plan__card">
            <div>
              <div className="confirm-plan__card-title">
                <h2>Godišnji</h2>
                <div className="confirm-plan__discount">
                  <Paragraph variant="title">Popust 38€</Paragraph>
                </div>
              </div>
              <Paragraph variant="p1">12 meseci</Paragraph>
            </div>
            <div>
              <Paragraph variant="p2">
                Plan uključuje pristup svom sadržaju sa platforme u narednih godinu
                dana od trenutka kupovine. U svakom trenutku se možete odjaviti.
              </Paragraph>
            </div>
            <h2>190€</h2>
          </div>
        )}
        {state?.subscriptionType === PlanTypes.SixMonths && (
          <div className="confirm-plan__card">
            <div>
              <div className="confirm-plan__card-title">
                <h2>Pristup platformi 6 meseci</h2>
                <div className="confirm-plan__discount">
                  <Paragraph variant="title">Popust 19€</Paragraph>
                </div>
              </div>
              <Paragraph variant="p1">6 meseci</Paragraph>
            </div>
            <div>
              <Paragraph variant="p2">
                Plan uključuje pristup svom sadržaju sa platforme u narednih šest meseci od trenutka kupovine.
              </Paragraph>
            </div>
            <h2>95€</h2>
          </div>
        )}
        <div className="confirm-plan__cta">
          <Button
            variant="white-outlined"
            label="Ostali planovi"
            onClick={() => navigate(ROUTES.subscription)}
          />
          <Button
            variant="primary"
            label={buttonLabel}
            isLoading={isCheckoutSubscriptionLoading}
            onClick={handleCheckout}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPlan;

import Category from "../components/category/Category";
import Carousel from "../components/carousel/Carousel";
import { TagType } from "../types";
import { useFeaturedTags } from "../hooks/useFeaturedTags";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { useState } from "react";
import { ReactComponent as ArrowShortLeft } from "../assets/icons/arrow-short-left.svg";
import { ReactComponent as ArrowShortRight } from "../assets/icons/arrow-short-right.svg";

// TO DO: DODATI STRELICE ZA CAROUSEL
const Categories = () => {
  const { data } = useFeaturedTags();
  const navigate = useNavigate();
  const [splide, setSplide] = useState();

  return (
    <div className="categories">
      <div className="categories_slide_wrap">
        <div className="categories_slide_header">
          <h1 className="categories_title">Koji izazov želiš da prevaziđeš?</h1>
          <div className="slider__arrows">
            {/* @ts-ignore */}
            <button onClick={() => splide?.go("<")}>
              <ArrowShortLeft />
            </button>
            {/* @ts-ignore */}
            <button onClick={() => splide?.go(">")}>
              <ArrowShortRight />
            </button>
          </div>
        </div>
        <div className="categories_slider">
          <Carousel
            // @ts-ignore
            items={data}
            component={(tag: TagType) => {
              return (
                <Category
                  {...tag}
                  onClick={() => navigate(`${ROUTES.content}?tagIds=${tag.id}`)}
                />
              );
            }}
            options={{
              autoWidth: false,
              showPagination: false,
              pagination: true,
              gap: 0,
              arrows: false,
            }}
            setSplide={setSplide}
          />
        </div>
      </div>
    </div>
  );
};

export default Categories;

import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

interface SubscriptionStore {
  subscriptionType: string | null
  setSubscriptionType: ({subscriptionType}: {
    subscriptionType: string | null
  }) => void
  removeSubscriptionType: () => void
}

const useSubscriptionStore = create<SubscriptionStore>()(
  devtools(
    persist((set) => ({
      subscriptionType: null,
      setSubscriptionType: ({ subscriptionType }) => {
        set({ subscriptionType })
      },
      removeSubscriptionType: () => set({subscriptionType: null})
    }),
    {
      name: 'subscription-store',
      storage: createJSONStorage(() => sessionStorage),
    })
  )
)

export default useSubscriptionStore
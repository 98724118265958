import React from "react";
import Paragraph from "../paragraph/Paragraph";
import Button from "../button/Button";
import { ReactComponent as ConfirmIcon } from "../../assets/icons/confirm.svg";
import { ReactComponent as CartIcon } from "../../assets/icons/cart.svg";
import { trim } from "../../utils/trim";
import { useCheckoutSubscription } from "../../hooks/useCheckoutSubscription";
import { useChangeSubscription } from "../../hooks/useChangeSubscription";
import { convertFromISO8601 } from "../../utils/convertToIso8601";
import { ROUTES } from "../../constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SubscriptionDetails,
  SubscriptionPriceType,
} from "../../types";
import { useCancelSubscription } from "../../hooks/useCancelSubscription";
import useAuthPersistStore from "../../store/authPersistStore";
import useSubscriptionStore from "../../store/subscriptionStore";
import { useExtendSubscription } from "../../hooks/useExtendSubscription";
import { PlanTypes } from "../../types";

interface SubscriptionPlanProps {
  title: string;
  subtitle: string;
  description: string;
  items: { checked: boolean; text: string }[];
  price?: string;
  discount?: number;
  isActive: boolean;
  duration?: string | null;
  subscriptionType: string;
  subscriptionDetails: SubscriptionDetails | undefined;
  subscriptionPrice: SubscriptionPriceType | undefined;
}

const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({
  title,
  subtitle,
  description,
  items,
  price,
  subscriptionType,
  subscriptionDetails,
  subscriptionPrice,
  discount,
}) => {
  const {
    mutate: checkoutSubscription,
    isLoading: isCheckoutSubscriptionLoading,
  } = useCheckoutSubscription();
  const { mutate: extendSubscription, isLoading: isExtendSubscriptionLoading } =
    useExtendSubscription();
  const { setSubscriptionType } = useSubscriptionStore();
  const { mutate: changeSubscription, isLoading: isChangeSubscriptionLoading } =
    useChangeSubscription();
  const { mutate: cancelSubscription, isLoading: isCancelSubscriptionLoading } =
    useCancelSubscription();
  const { isAuthenticated } = useAuthPersistStore();
  const navigate = useNavigate();

  const location = useLocation();
  const isActive = subscriptionDetails?.subscriptionType === subscriptionType;
  const isNotActive = subscriptionDetails?.subscriptionType === undefined;
  const planScheduledToCancel = subscriptionDetails?.scheduledToCancel;

  const renderButtons = () => {
    if (isAuthenticated && planScheduledToCancel && isActive) {
      return (
        <Button
          leftIcon={<CartIcon />}
          variant="primary"
          label="Produži plan"
          hasLoader={true}
          isLoading={isExtendSubscriptionLoading}
          onClick={() => {
            if (!isAuthenticated) {
              navigate(ROUTES.login);
              return;
            }

            extendSubscription(
              {
                successUrl: `${process.env.REACT_APP_BASE_URL}${ROUTES.checkoutSuccess}?redirectTo=${location?.pathname}`,
                cancelUrl: window.location.href,
              },
              {
                onSuccess: (variable) => {
                  // @ts-ignore
                  const { checkoutLink } = variable;

                  if (checkoutLink) {
                    window.location.href = checkoutLink;
                  }
                },
              }
            );
          }}
        />
      );
    }

    if (isAuthenticated && isActive) {
      return (
        <div className="subscription-plan-item_owned">
          <Button
            variant="error"
            label="Otkaži plan"
            disabled={isCancelSubscriptionLoading}
            isLoading={isCancelSubscriptionLoading}
            hasLoader={true}
            onClick={() =>
              cancelSubscription(
                {
                  customerPortalFlowType: "subscription_cancel",
                  successUrl: `${process.env.REACT_APP_BASE_URL}${ROUTES.checkoutSuccess}?redirectTo=${location?.pathname}&type=subscription_cancel`,
                  cancelUrl: window.location.href,
                },
                {
                  onSuccess: (variable) => {
                    // @ts-ignore
                    const { checkoutLink } = variable;

                    if (checkoutLink) {
                      window.location.href = checkoutLink;
                    }
                  },
                }
              )
            }
          />
          <Button
            variant="white-outlined"
            label="Promeni detalje plaćanja"
            disabled={isChangeSubscriptionLoading}
            isLoading={isChangeSubscriptionLoading}
            hasLoader={true}
            onClick={() =>
              changeSubscription(
                {
                  customerPortalFlowType: "payment_method_update",
                  successUrl: `${process.env.REACT_APP_BASE_URL}${ROUTES.checkoutSuccess}?redirectTo=${location?.pathname}&type=payment_method_update`,
                  cancelUrl: window.location.href,
                },
                {
                  onSuccess: (variable) => {
                    // @ts-ignore
                    const { checkoutLink } = variable;

                    if (checkoutLink) {
                      window.location.href = checkoutLink;
                    }
                  },
                }
              )
            }
          />
        </div>
      );
    }
    //planovi koji imaju free trial
    const specialSubscriptionTypes = [
      PlanTypes.Monthly,
      PlanTypes.SixMonths,
      PlanTypes.Yearly
    ];
    //u zavisnosti od plana, label ima drugi tekst
    //za ovaj slucaj imamo nemamo free trial za 3meseca i 2meseca, pa nam treba drugaciji tekst
    const buttonLabel = specialSubscriptionTypes.includes(subscriptionType as PlanTypes)
      ? "Započni 48h besplatnog perioda"
      : "Uplata za radionicu & izazov";
    

    return (
      <Button
        leftIcon={<CartIcon />}
        variant="primary"
        label={buttonLabel} //zavisno od plana, pisace odgovarajuci tekst
        disabled={!isNotActive}
        hasLoader={true}
        isLoading={isCheckoutSubscriptionLoading}
        onClick={() => {
          if (!isAuthenticated) {
            setSubscriptionType({ subscriptionType });
            navigate(ROUTES.login);
            return;
          }

          checkoutSubscription(
            {
              subscriptionType,
              successUrl: `${process.env.REACT_APP_BASE_URL}${ROUTES.checkoutSuccess}?redirectTo=${location?.pathname}`,
              cancelUrl: window.location.href,
            },
            {
              onSuccess: (variable) => {
                // @ts-ignore
                const { checkoutLink } = variable;

                if (checkoutLink) {
                  window.location.href = checkoutLink;
                }
              },
            }
          );
        }}
      />
    );
  };

  return (
    <div
      className={trim(
        `subscription-plan ${isActive ? "subscription-plan--active" : ""}`
      )}
    >
      <div className="subscription-plan_header">
        <div className="subscription-pan_title-wrap">
          <h2 className="subscription-plan_title">{title}</h2>
          {discount && (
            <div className="subscription-plan_discount">
              <Paragraph variant="title">Popust {discount}€</Paragraph>
            </div>
          )}
        </div>
        {isActive && (
          <div className="subscription-plan_subscribed">
            <ConfirmIcon />
          </div>
        )}
      </div>
      <div>
        <Paragraph
          variant="p1"
          className={trim(
            `subscription-plan_type ${price && "subscription-plan_type--plan"}`
          )}
        >
          {subtitle}{" "}
          <span>
            {subscriptionPrice
              ? `${Math.round(subscriptionPrice?.amount / 100)}`
              : '--'}€
          </span>
        </Paragraph>
      </div>
      {subscriptionDetails?.currentPeriodActiveUntil && isActive && (
        <>
          <div className="subscription-plan_subscription">
            <Paragraph variant="p2">
              {planScheduledToCancel ? "otkazivanje" : "obnova"}{" "}
              {convertFromISO8601(
                subscriptionDetails?.currentPeriodActiveUntil
              )}
              .
            </Paragraph>
          </div>
        </>
      )}
      <Paragraph variant="p2" className="subscription-plan_description">
        {description}
      </Paragraph>
      <ul className="subscription-plan_plan">
        {items.map(({ checked, text }) => (
          <div
            className={trim(
              `subscription-plan-item ${
                checked && "subscription-plan-item--checked"
              }`
            )}
            key={text}
          >
            <div className="subscription-plan-item_indicator">
              {checked && <ConfirmIcon />}
            </div>
            <p>{text}</p>
          </div>
        ))}
      </ul>
      {renderButtons()}
    </div>
  );
};

export default SubscriptionPlan;

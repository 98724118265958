import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./app.scss";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterSuccessfully from "./pages/RegisterSuccessfully";
import CreateProfile from "./pages/CreateProfile";
import Header from "./components/header/Header";
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import CreateProfileSuccessfully from "./pages/CreateProfileSuccessfully";
import Serial from "./pages/Serial";
import Episode from "./pages/Episode";
import Content from "./pages/Content";
import Footer from "./components/footer/Footer";
import ForgotPasswordSuccess from "./pages/ForgotPasswordSuccess";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordSuccess from "./pages/ResetPasswordSuccess";
import MyProfile from "./pages/MyProfile";
import ChangeProfile from "./pages/ChangeProfile";
import ChangeProfileSuccess from "./pages/ChangeProfileSuccess";
import ChangePasswordRequest from "./pages/ChangePasswordRequest";
import ChangePasswordRequestSuccess from "./pages/ChangePasswordRequestSuccess";
import ChangeEmail from "./pages/ChangeEmail";
import ChangeEmailSuccess from "./pages/ChangeEmailSuccess";
import ResetEmail from "./pages/ResetEmail";
import ResetEmailSuccess from "./pages/ResetEmailSuccess";
import Subscription from "./pages/Subscription";
import AboutUs from "./pages/AboutUs";
import PrivateRoute from "./router/PrivateRoute";
import countries from "i18n-iso-countries";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Educators from "./pages/Educators";
import Educator from "./pages/Educator";
import Main from "./components/main/Main";
import ChangePassword from "./pages/ChangePassword";
import ChangePasswordSuccess from "./pages/ChangePasswordSuccess";
import { ROUTES } from "./constants/routes";
import PageNotFound from "./pages/PageNotFound";
import Banner from "./components/banner/Banner";
import MyContent from "./pages/MyContent";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import Legal from "./pages/Legal";
import "react-tooltip/dist/react-tooltip.css";
import ConfirmPlan from "./pages/ConfirmPlan";
import { Promotion } from "./components/promotion/Promotion";
import PromotionDesktop from "./components/promotion/PromotionDesktop";

countries.registerLocale(require("i18n-iso-countries/langs/hr.json"));
export const queryClient = new QueryClient();

const App = () => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <BrowserRouter>
          <Banner />
          <PromotionDesktop />
          <Header />
          <Main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path={ROUTES.login} element={<Login />} />
              <Route path={ROUTES.register} element={<Register />} />
              <Route
                path={ROUTES.registerSuccess}
                element={<RegisterSuccessfully />}
              />
              <Route path={ROUTES.createProfile} element={<CreateProfile />} />

              <Route
                path={ROUTES.createProfileSuccess}
                element={<CreateProfileSuccessfully />}
              />
              <Route
                path={ROUTES.forgotPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={ROUTES.forgotPasswordSuccess}
                element={<ForgotPasswordSuccess />}
              />
              <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
              <Route
                path={ROUTES.resetPasswordSuccess}
                element={<ResetPasswordSuccess />}
              />
              <Route path={ROUTES.home} element={<Home />} />
              <Route
                path={ROUTES.profile}
                element={
                  <PrivateRoute>
                    <MyProfile />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.changeProfile}
                element={
                  <PrivateRoute>
                    <ChangeProfile />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.changePassword}
                element={
                  <PrivateRoute>
                    <ChangePassword />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.changePasswordSuccess}
                element={
                  <PrivateRoute>
                    <ChangePasswordSuccess />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.changeProfileSuccess}
                element={
                  <PrivateRoute>
                    <ChangeProfileSuccess />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.changePasswordRequest}
                element={
                  <PrivateRoute>
                    <ChangePasswordRequest />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.changePasswordRequestSuccess}
                element={
                  <PrivateRoute>
                    <ChangePasswordRequestSuccess />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.changeEmail}
                element={
                  <PrivateRoute>
                    <ChangeEmail />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.changeEmailSuccess}
                element={
                  <PrivateRoute>
                    <ChangeEmailSuccess />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.checkoutSuccess}
                element={
                  <PrivateRoute>
                    <CheckoutSuccess />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.resetPassword}
                element={
                  <PrivateRoute>
                    <ResetEmail />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.resetPasswordSuccess}
                element={
                  <PrivateRoute>
                    <ResetEmailSuccess />
                  </PrivateRoute>
                }
              />
              <Route path={ROUTES.legal} element={<Legal />} />
              <Route path={ROUTES.educators} element={<Educators />} />
              <Route path="/educator/:id" element={<Educator />} />
              <Route path={ROUTES.subscription} element={<Subscription />} />
              <Route path={ROUTES.aboutUs} element={<AboutUs />} />
              <Route path="/serial/:id" element={<Serial />} />
              <Route
                path="/serial/:serialId/episode/:episodeId"
                element={<Episode />}
              />
              <Route path={ROUTES.content} element={<Content />} />
              <Route
                path={ROUTES.myContent}
                element={
                  <PrivateRoute>
                    <MyContent />
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.confirmPlan}
                element={
                  <PrivateRoute>
                    <ConfirmPlan />
                  </PrivateRoute>
                }
              />
              <Route path={ROUTES.pageNotFound} element={<PageNotFound />} />
            </Routes>
          </Main>
          <Footer />
          <Promotion />
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
};

export default App;

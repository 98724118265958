import { AxiosRequestConfig, AxiosResponse } from "axios";
import api from "./instance";

// Define a generic type for the data or params that can be of any shape
export type DataOrParams = Record<string, any>;

// Define a generic type for the makeRequest function
const makeRequest = async <T>(
  method: AxiosRequestConfig["method"],
  endpoint: string,
  dataOrParams: DataOrParams = {}
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api({
      method,
      url: endpoint,
      ...(method === "get" ? { params: dataOrParams } : { data: dataOrParams }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Define generic functions for different HTTP methods
export const GET = async <T>(endpoint: string, params?: DataOrParams): Promise<T> =>
  makeRequest<T>("get", endpoint, params);

export const POST = async <T>(endpoint: string, data: DataOrParams): Promise<T> =>
  makeRequest<T>("post", endpoint, data);

export const PUT = async <T>(endpoint: string, data: DataOrParams): Promise<T> =>
  makeRequest<T>("put", endpoint, data);

export const PATCH = async <T>(endpoint: string, data: DataOrParams): Promise<T> =>
  makeRequest<T>("patch", endpoint, data);

export const DELETE = async <T>(endpoint: string): Promise<T> =>
  makeRequest<T>("delete", endpoint);

import { useMutation } from "@tanstack/react-query";
import { DataOrParams, PATCH } from "../services/requests";
import { ENDPOINTS } from "../constants/endpoints";

export const useUpdateProfile = () => {
  const mutation = useMutation({
    mutationFn: async (payload: DataOrParams) => {
        const data = await PATCH(`${ENDPOINTS.updateProfile}`, payload);
        return data;
    }
});

  return { ...mutation };
};

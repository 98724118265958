import React from "react";
import ReactPlayer from "react-player/lazy";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";

const VideoPlayer = ({
  url,
  light,
  isPlaying,
  hideLight,
}: {
  url?: string;
  light: any;
  isPlaying?: boolean;
  hideLight?: boolean;
}) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        controls={true}
        light={!hideLight && light}
        url={url}
        width="100%"
        height="100%"
        playing={isPlaying}
        playIcon={
          <button className="player-wrapper__icon">
            <PlayIcon />
          </button>
        }
      />
    </div>
  );
};

export default VideoPlayer;

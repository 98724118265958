import { useEffect, useRef, useState } from "react";
import Badge from "../components/badge/Badge";

import Button from "../components/button/Button";
import Preview from "../components/preview/Preview";
import { ReactComponent as PlayIcon } from "../assets/icons/play.svg";
import Paragraph from "../components/paragraph/Paragraph";
import useAuthPersistStore from "../store/authPersistStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import isEmpty from "lodash.isempty";
import Empty from "../components/empty/Empty";
import Share from "../components/share/Share";
import VideoPlayer from "../components/player/VideoPlayer";
import Carousel from "../components/carousel/Carousel";
import {
  ContentFormatType,
  EducatorType,
  EpisodeType,
} from "../types";
import { getImageRoute } from "../utils/getImageRoute";
import { useContentFormats } from "../hooks/useContentFormats";
import { useSerial } from "../hooks/useSerial";
import Spinner from "../components/spinner/Spinner";
import { ROUTES } from "../constants/routes";
import { useSubscriptionDetails } from "../hooks/useSubscriptionDetails";
import { useMyContent } from "../hooks/useMyContent";
import { useCheckoutForSerial } from "../hooks/useCheckoutSerial";
import CoursePrice from "../components/coursePrice/CoursePrice";
import { ReactComponent as CartIcon } from "../assets/icons/cart.svg";

const Serial = () => {
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthPersistStore();
  const [isPlaying, setIsPlaying] = useState(false);
  const [hideLight, setHideLight] = useState(false);
  const { state } = useLocation();
  const { data } = useContentFormats();
  const { id } = useParams();
  const { data: serial, isLoading } = useSerial(id);
  // const { mutate: buySerial, isLoading: isBuySerialLoading } =
  //   useCheckoutForSerial();
  const [currentContentFormat, setcurrentContentFormat] = useState<
    ContentFormatType | undefined
  >();

  const { data: subscriptionDetails } = useSubscriptionDetails({
    isAuthenticated,
  });
  const { data: myContent } = useMyContent({ isAuthenticated });
  const location = useLocation();
  const currentSerial = state || serial;
  // const shouldBuyFirst =
  //   (!subscriptionDetails?.subscriptionType ||
  //     subscriptionDetails?.subscriptionAtRisk) &&
  //   !myContent?.includes(currentSerial?.id) &&
  //   currentSerial?.stripePriceId;

  useEffect(() => {
    setcurrentContentFormat(
      data?.find((item) => item.id === currentSerial?.contentFormatId)
    );
  }, [currentSerial, data]);

  useEffect(() => {
    if (!isLoading && !currentSerial?.id) {
      navigate(ROUTES.home);
    }
  }, [currentSerial?.id, isLoading, navigate]);

  const shouldShowPlayButton = () => {
    return isPlayButtonDisabled() || !isAuthenticated;
  };

  const handleStartFromBeginning = () => {
    if (shouldShowPlayButton()) {
      navigate(ROUTES.subscription);
      return;
    }

    if (isEmpty(currentSerial?.episodes)) {
      return;
    }

    const firstEpisode =
      currentSerial?.episodes && currentSerial?.episodes[0]?.id;
    navigate(`/serial/${serial?.id}/episode/${firstEpisode}`, {
      state: {
        episode: { ...currentSerial?.episodes[0] },
        serial: { ...currentSerial },
      },
    });
  };

  // const handleBuyCourse = () => {
  //   buySerial(
  //     {
  //       serialId: id,
  //       successUrl: `${process.env.REACT_APP_BASE_URL}${ROUTES.checkoutSuccess}?redirectTo=${location?.pathname}`,
  //       cancelUrl: window.location.href,
  //     },
  //     {
  //       onSuccess: (variable) => {
  //         // @ts-ignore
  //         const { checkoutLink } = variable;

  //         if (checkoutLink) {
  //           window.location.href = checkoutLink;
  //         }
  //       },
  //     }
  //   );
  // };

  const togglePlayback = () => {
    setHideLight(true);
    setIsPlaying(!isPlaying);
  };

  if (isLoading && !state) {
    return <Spinner />;
  }

  const isPlayButtonDisabled = () => {
    if (!currentSerial?.stripePriceId) {
      return (
        isEmpty(currentSerial?.episodes) ||
        !isAuthenticated ||
        !subscriptionDetails?.subscriptionType ||
        subscriptionDetails?.subscriptionAtRisk
      );
    }

    if (myContent?.includes(currentSerial?.id)) {
      return false;
    }

    if (
      subscriptionDetails?.subscriptionType &&
      !subscriptionDetails?.subscriptionAtRisk
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="serial">
      <div className="serial_content">
        <div>
          <div className="serial_cover">
            <VideoPlayer
              isPlaying={isPlaying}
              hideLight={hideLight}
              url={currentSerial?.trailerYoutubeLink}
              light={
                <img
                  alt={currentSerial?.title}
                  src={getImageRoute(currentSerial?.horizontalCoverImage)}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              }
            />
            {/* {shouldBuyFirst && (
              <CoursePrice serialId={currentSerial?.stripePriceId} />
            )} */}
          </div>
          <div ref={headerRef}>
            <div className="serial_rating">
              {currentContentFormat && (
                <Badge
                  variant="outlined"
                  size="md"
                  contentFormat={currentContentFormat}
                  onClick={() =>
                    navigate(
                      `${ROUTES.content}?contentFormatIds=${currentContentFormat?.id}`
                    )
                  }
                />
              )}
            </div>
            <div className="serial_info">
              <h2>{currentSerial?.title}</h2>
              <Paragraph variant="p2">{currentSerial?.description}</Paragraph>
              <Paragraph variant="p2" className="serial_educator">
                {currentSerial?.educators?.length > 1 ? (
                  <>Edukatori: </>
                ) : (
                  <>Edukator: </>
                )}
                {currentSerial?.educators.map(
                  (educator: EducatorType, index: number) => {
                    return (
                      <span
                        key={educator?.fullName}
                        onClick={() =>
                          navigate(`${ROUTES.educator}/${educator?.id}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {educator?.fullName}
                        {index < currentSerial?.educators.length - 1
                          ? ", "
                          : ""}
                      </span>
                    );
                  }
                )}
              </Paragraph>
            </div>
            <div className="serial_cta">
              <div>
                <Button
                  label="Trejler"
                  variant="blue"
                  leftIcon={<PlayIcon />}
                  onClick={togglePlayback}
                />
                {/* {shouldBuyFirst ? (
                  <Button
                    label="Kupi Kurs"
                    variant="primary"
                    hasLoader={true}
                    isLoading={isBuySerialLoading}
                    leftIcon={<CartIcon />}
                    onClick={handleBuyCourse}
                    disabled={isBuySerialLoading || !isAuthenticated}
                  />
                ) : ( */}
                  <Button
                    label={
                      shouldShowPlayButton() ? "Odaberi plan" : "Gledaj od početka"
                    }
                    variant="primary"
                    leftIcon={
                      shouldShowPlayButton() ? <CartIcon /> : <PlayIcon />
                    }
                    onClick={handleStartFromBeginning}
                  />
                {/* )} */}
              </div>
              <Share />
            </div>
          </div>
        </div>

        <div className="episode_rest">
          <Paragraph variant="p1">Epizode</Paragraph>
          {!isEmpty(currentSerial?.episodes) && (
            <Paragraph variant="p2">
              Broj epizoda: {currentSerial?.episodeCount}
            </Paragraph>
          )}
          <div className="episode_carousel">
            {isEmpty(currentSerial?.episodes) ? (
              <Empty emptySlide={{ type: "vertical" }} />
            ) : (
              <>
                <Carousel
                  items={currentSerial?.episodes}
                  component={(item: EpisodeType, index: number) => (
                    <Preview
                      variant="vertical"
                      episode={item}
                      serial={currentSerial}
                      index={index}
                      isDisabled={isPlayButtonDisabled()}
                    />
                  )}
                  options={{
                    gap: 6,
                    padding: 0,
                    perMove: 1,
                    fixedWidth: 280,
                    autoWidth: false,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Serial;

import { useQuery } from "@tanstack/react-query";
import { SerialType } from "../types";

import { GET } from "../services/requests";

export const useSerialsByFormat = ({id}: {
    id: string,
}) => {
  const query = useQuery<SerialType[], Error, SerialType[]>({
    // @ts-ignore
    queryKey: ["SERIALS", id],
    queryFn: async () => {
      const data = await GET(`/serials/format-content-order/${id}`);
      return data;
    },
    enabled: !!id,
    staleTime: 5 * (60 * 1000), 
    cacheTime: 7 * (60 * 1000),
  });

  return { ...query };
};

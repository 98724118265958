import Paragraph from "../components/paragraph/Paragraph";
import ContentCategory from "../sections/ContentCategory";
import { useEffect, useState } from "react";
import Spinner from "../components/spinner/Spinner";
import { useMyContent } from "../hooks/useMyContent";
import { useSerialsById } from "../hooks/useSerialsById";
import useAuthPersistStore from "../store/authPersistStore";

const MyContent = () => {
  const { isAuthenticated } = useAuthPersistStore();
  const { data, isLoading: isMyContentLoading } = useMyContent({
    isAuthenticated,
  });
  const { mutate, isLoading: isSerialsByIdLoading } = useSerialsById();
  const [serials, setSerials] = useState([]);

  useEffect(() => {
    if (data) {
      mutate(
        { ids: data },
        {
          onSuccess: (data) => {
            if (data) {
              // @ts-ignore
              setSerials(data);
            } else {
              setSerials([]);
            }
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, data);

  // @ts-ignore
  if (isMyContentLoading || isSerialsByIdLoading) {
    return <Spinner />;
  }

  return (
    <div className="content">
      <div>
        <div className="content_header">
          <h1>Sadržaj</h1>
          {/* @ts-ignore */}
          <Paragraph variant="p1">{serials?.length || 0}</Paragraph>
        </div>
        <ContentCategory
          // @ts-ignore
          items={serials}
          isLoading={isMyContentLoading || isSerialsByIdLoading}
        />
      </div>
    </div>
  );
};

export default MyContent;

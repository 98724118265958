import React from "react";
import Paragraph from "../components/paragraph/Paragraph";
import { useEducators } from "../hooks/useEducators";
import Educator from "../components/educator/Educator";
import { useNavigate } from "react-router-dom";
import { EducatorType } from "../types";
import Spinner from "../components/spinner/Spinner";
import { ROUTES } from "../constants/routes";

const Educators = () => {
  const { data: educators, isLoading: isEducatorsLoading } = useEducators();
  const navigate = useNavigate();

  if (isEducatorsLoading) {
    return <Spinner />;
  }

  return (
    <div className="educators">
      <div>
        <h1>Edukatori</h1>
        <Paragraph variant="p2">
          Neki od najmoćnijih lidera, na našim prostorima, okupiće se da podele
          svoja najdublja razmišljanja i smernice za korak napred u ovom
          ključnom trenutku.
        </Paragraph>
      </div>
      <div className="educators_grid">
        {educators?.map((educator: EducatorType) => {
          return (
            <Educator
              key={`${educator.fullName} - ${educator.profession}`}
              onClick={() =>
                navigate(`${ROUTES.educator}/${educator?.id}`, {
                  state: { ...educator },
                })
              }
              educator={educator}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Educators;

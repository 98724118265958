import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": true,
  },
});

api.interceptors.request.use(
  (config) => {
    // Check if there is a JWT token in local storage
    const storedState = localStorage.getItem("auth-persist-store");

    if (storedState) {
      const { state } = JSON.parse(storedState);
      const accessToken = state?.accessToken;

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("API ERROR:", error);
    return Promise.reject(error);
  }
);

export default api;

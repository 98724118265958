import { ReactComponent as EmailVerificationIlustration } from "../assets/images/email-verification-ilustration.svg";
import Link from "../components/Link/Link";
import Paragraph from "../components/paragraph/Paragraph";
import Steps from "../components/steps/Steps";
import useAuthPersistStore from "../store/authPersistStore";
import useLogout from "../hooks/useLogout";

const ChangePasswordRequestSuccess = () => {
  const { user } = useAuthPersistStore();
  const { handleLogout } = useLogout();

  return (
    <div className="success">
      <Steps steps={4} activeStep={2} />
      <div className="success_page">
        <EmailVerificationIlustration />
        <div className="success_content">
          <h1>Zatražena promena lozinke</h1>
          <div className="success_text">
            <Paragraph variant="p2">
              Promenite vašu lozinku, tako što ćete ispratiti korake koje smo
              vam poslali na
            </Paragraph>
            <Paragraph variant="p2">{user?.email}</Paragraph>
          </div>
          <div className="success_link">
            <h3>Već ste promenili lozinku?</h3>
            <Link label="Prijavi se" variant="primary" onClick={handleLogout} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordRequestSuccess;
